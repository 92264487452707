export enum LedgerHead {
  Obtainly = "obtainly",
}

export enum LedgerSubhead {
  Transfers = "transfers",
  Balance = "balance",
  Interest = "interest",
  InterestRaven = "interest-raven",
  Principal = "principal",
  PrincipalRaven = "principal-raven",
  All = "",
  Funding = "funding",
  PayIn = "pay-in",
  CashBackOut = "cash-back-out",
  CashBackIn = "cash-back-in",
  PayOut = "pay-out",
  PayBack = "pay-back",
}
