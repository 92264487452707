import { UploadModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class UploadService extends ModelService<UploadModel> {
  constructor() {
    super("upload");
  }
}

export const uploadService = new UploadService();
