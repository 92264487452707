import isMobileDevice from "ismobilejs";
import lodash from "lodash";
import { RefObject, useCallback, useEffect } from "react";

export function useScrollElementIntoView<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
) {
  const detectedMobile = isMobileDevice(window.navigator).any;
  const throttled = useCallback(() => {
    return lodash.throttle(() => {
      if (document.activeElement === ref.current && detectedMobile) {
        ref.current?.scrollIntoView(true);
      }
    }, 800);
  }, [ref, detectedMobile]);

  useEffect(() => {
    const subscription = throttled();
    window?.visualViewport?.addEventListener("resize", subscription);

    return () => {
      window?.visualViewport?.removeEventListener("resize", subscription);
    };
  }, [throttled]);
}
