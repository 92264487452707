import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { useFormWithSchema } from "hooks";
import { FC } from "react";
import { Controller } from "react-hook-form";
import * as Yup from "yup";

const authCodeFormSchema = Yup.object({
  code: Yup.string()
    .matches(/[0-9]{6}/g, "Invalid OTP")
    .required()
    .label("One Time Password"),
});

type FormInputType = Yup.InferType<typeof authCodeFormSchema>;

interface AuthCodeFormProps {
  isLoading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: (code: string) => void;
}

export const AuthCodeForm: FC<AuthCodeFormProps> = ({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useFormWithSchema(authCodeFormSchema, { defaultValues: { code: "" } });

  const onSubmitOtpForm = (values: FormInputType) => onSubmit(values.code);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={isLoading ? () => null : undefined}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton isDisabled={isLoading} />

        <ModalBody h="auto" maxH="auto">
          <Heading size="md" my="20px" textAlign="center">
            Authentication Code
          </Heading>
          <Text color="gray.700" textAlign="center" mb="20px">
            Enter verification code displayed on Google Authenticator App
          </Text>
          <Text textAlign="center" mb="6px" fontSize="14px" fontWeight="medium">
            One Time Password (OTP)
          </Text>
          <form onSubmit={handleSubmit(onSubmitOtpForm)}>
            <Controller
              name="code"
              control={control}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <HStack align="center" justify="center">
                  <PinInput
                    otp
                    type="number"
                    value={value}
                    onChange={(newValue) =>
                      onChange({ target: { value: newValue } })
                    }
                    isInvalid={!!error?.message}
                    isDisabled={isLoading}
                    {...rest}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              )}
            />
            <Box textAlign="center" my="20px">
              <Button
                colorScheme="primary"
                type="submit"
                isDisabled={isLoading || !isValid || !isDirty}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </Box>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
