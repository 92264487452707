import { RateSettingModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class RateSettingService extends ModelService<RateSettingModel> {
  constructor() {
    super("rates");
  }

  readAvailableRates = (params: {
    businessId?: string;
    amount?: number;
    duration: number;
  }) => {
    return this.api
      .post<{ data: Array<RateSettingModel> }>(
        `/${this.modelName}/available_rates`,
        params
      )
      .then(({ data }) => data.data);
  };

  readAvailableDurations = (businessId: string) => {
    return this.api
      .get<{ data: Array<number> }>(
        `/${this.modelName}/available_durations?businessId=${businessId}`
      )
      .then(({ data }) => data.data);
  };
}

export const rateSettingService = new RateSettingService();
