import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  ImageProps,
  Text,
} from "@chakra-ui/react";
import NextImage from "next/image";
import pluralize from "pluralize";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { getFileName, getFileType } from "utils";
import DefaultImage from "./img-placeholder.svg";
import PDFImage from "./pdf-placeholder.svg";

interface FileSelectorPreviewProps {
  isOpen: boolean;
  onClose: VoidFunction;
  files: Map<string, File>;
  errors: Map<string, string>;
  onSelectFiles: (files: Map<string, File>) => void;
}

export const FileSelectorPreview: React.FC<FileSelectorPreviewProps> = ({
  isOpen,
  onClose,
  files,
  // errors,
  onSelectFiles,
}) => {
  const boxRef = useRef<any>();
  const [mutableFiles, setMutableFiles] = useState(() => new Map());
  const [selectedFileId, setSelectedFileId] = useState<string>("");

  const onSelect = (id: string) => {
    if (mutableFiles.has(id)) {
      setSelectedFileId(id);
    }
  };

  const onRemove = (id: string) => {
    if (mutableFiles.has(id)) {
      setSelectedFileId("");
      setMutableFiles((prev) => {
        const newMappedFiles = new Map(prev);
        newMappedFiles.delete(id);
        return newMappedFiles;
      });
      if (mutableFiles.size === 1) {
        onClose();
      }
    }
  };

  const pushFiles = useCallback(() => {
    onSelectFiles?.(mutableFiles);
  }, [mutableFiles, onSelectFiles]);

  useEffect(() => {
    if (files.size) setSelectedFileId(Array.from(files.keys())[0]);
    setMutableFiles(() => new Map(files));
  }, [files]);

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Select files</DrawerHeader>
          <DrawerBody ref={boxRef}>
            <Grid
              templateColumns="1fr"
              templateRows="auto 1fr auto"
              justifyItems="stretch"
              maxW="100%"
              height="full"
              maxH="100%"
            >
              <GridItem>
                <Flex pb="10px" align="center" justify="space-between">
                  <Text fontSize="md" fontWeight="medium">
                    {mutableFiles.size} {pluralize("file", mutableFiles.size)}{" "}
                    selected
                  </Text>
                  <Button size="sm" colorScheme="success" onClick={pushFiles}>
                    Upload
                  </Button>
                </Flex>
              </GridItem>
              <GridItem
                position="relative"
                overflow="auto"
                borderRadius="10px"
                boxShadow="md"
              >
                <FileThumbnail
                  key={selectedFileId}
                  file={mutableFiles.get(selectedFileId)}
                  size="full"
                  objectFit="contain"
                  borderRadius="0px"
                />
                <Box>
                  {!!mutableFiles.get(selectedFileId) && (
                    <Text
                      position="absolute"
                      p="10px"
                      left={0}
                      right={0}
                      bottom={0}
                      color="white"
                      bgColor="rgba(0,0,0,0.3)"
                    >
                      {getFileName(mutableFiles.get(selectedFileId))}
                    </Text>
                  )}
                </Box>
              </GridItem>

              <GridItem overflowX="auto" pt="20px">
                <FileStrip
                  files={mutableFiles}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  selectedMapId={selectedFileId}
                />
              </GridItem>
            </Grid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface FileStripProps {
  onSelect: (mapId: string) => void;
  onRemove: (mapId: string) => void;
  files: Map<string, File>;
  selectedMapId?: string;
}

const FileStrip: FC<FileStripProps> = ({
  files,
  onSelect,
  onRemove,
  selectedMapId,
}) => {
  return (
    <Box overflowX="auto" width="full">
      <HStack align="center" gap="4px">
        {Array.from(files.entries())?.map(([id, file]) => (
          <Box
            key={id}
            onClick={() => onSelect(id)}
            borderWidth="4px"
            borderColor={selectedMapId === id ? "primary.100" : "gray.50"}
            position="relative"
            borderRadius="10px"
          >
            <FileThumbnail
              file={file}
              minW="120px"
              height="120px"
              objectFit="cover"
              borderRadius="6px"
            />
            <IconButton
              aria-label="remove-file"
              icon={<RiCloseFill />}
              onClick={() => onRemove(id)}
              size="sm"
              position="absolute"
              top="4px"
              right="4px"
              boxShadow="sm"
              colorScheme="blackAlpha"
            >
              Remove
            </IconButton>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

interface FileThumbnailProps extends ImageProps {
  file?: File;
  size?: string;
  objectFit?: "cover" | "contain" | "fill";
}

const FileThumbnail: FC<FileThumbnailProps> = ({
  file,
  size,
  objectFit,
  ...rest
}) => {
  const [image, setImage] = useState<string>();
  useEffect(() => {
    // read image
    const reader = new FileReader();
    if (file) {
      reader.onloadend = async () => {
        setImage(reader.result?.toString());
      };
      reader.readAsDataURL(file);
    } else {
      setImage(DefaultImage);
    }

    return () => reader.abort();
  }, [file]);

  return (
    <Box position="relative" boxSize={size ?? "120px"}>
      <Image
        alt="file-thumbnail-view"
        as={NextImage}
        layout="fill"
        objectFit={objectFit || "cover"}
        src={image}
        fallbackSrc={
          file && getFileType(file) === "pdf" ? PDFImage : DefaultImage
        }
        borderRadius="10px"
        {...rest}
      />
    </Box>
  );
};
