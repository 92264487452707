import { api } from "utils";

export class ModelService<Model = any> {
  protected modelName = "";
  protected api = api;

  constructor(modelName: string) {
    this.modelName = modelName;
  }

  read = (_id?: string) => {
    return this.api
      .get<{ data: Model }>(`/${this.modelName}/read?_id=${_id}`)
      .then(({ data }) => data.data);
  };

  readAll = () => {
    return this.api
      .get<{ data: Model[] }>(`/${this.modelName}/read_all`)
      .then(({ data }) => data.data);
  };

  readBy = (payload: Partial<Model>) => {
    const queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key as keyof Model])
      .join("&");
    return api
      .get<{ data: Model }>(`/${this.modelName}/read_by?${queryString}`)
      .then(({ data }) => data.data);
  };

  readManyBy = (payload: Partial<Model>) => {
    const queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key as keyof Model])
      .join("&");
    return api
      .get<{ data: Model[] }>(`/${this.modelName}/read_many_by?${queryString}`)
      .then(({ data }) => data.data);
  };

  create = (payload: Partial<Model>) => {
    return this.api
      .post<{ status: string; message: string; data: Model }>(
        `/${this.modelName}/create`,
        payload
      )
      .then(({ data }) => data);
  };

  update = (payload: Partial<Model>) => {
    const { _id } = payload as any;
    return this.api
      .put<{ status: string; message: string }>(
        `/${this.modelName}/update?_id=${_id}`,
        payload
      )
      .then(({ data }) => data);
  };

  delete = (payload: Partial<Record<string, string>>) => {
    const queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    return this.api.delete(`/${this.modelName}/delete?${queryString}`);
  };
}
