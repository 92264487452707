import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiLockLine } from "react-icons/ri";

export const PoweredBadge = () => {
  return (
    <Flex gap="6px" alignItems="center">
      <Icon as={RiLockLine} color="gray.400" fontSize="lg" />

      <Text fontSize="sm" color="gray.600" textTransform="capitalize">
        Powered by{" "}
        <Text as="span" color="body">
          BESTSTAR MFB
        </Text>
      </Text>
    </Flex>
  );
};
