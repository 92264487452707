import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { Alert } from "./components/Alert";
import { Badge } from "./components/Badge";
import { Button } from "./components/Button";
import { Card } from "./components/Card";
import * as Form from "./components/Form";
import { Modal } from "./components/Modal";
import { Spinner } from "./components/Spinner";
import { Tabs } from "./components/Tabs";
import { Text } from "./components/Text";

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "gray.50",
        color: "body",
        fontSize: "15px",
      },
      a: {
        color: "primary.500",
      },
      button: {
        _focus: {
          outline: "none",
        },
      },
      "h1, h2, h3, h4, h5, h6": {
        color: "body",
        fontWeight: "semibold",
      },
      ".nested-scope-list": {
        ol: {
          counterReset: "item",
        },
        "li > ul": {
          paddingLeft: "30px",
        },
        "ol > li": {
          display: "block",
          indentStyle: "inherit",
        },
        "ol > li:before": {
          content: `counters(item, ". ") ". "`,
          counterIncrement: "item",
        },
      },
    },
  },
  fonts: {
    body: "Inter, system-ui, sans-serif",
    heading: "Archivo, system-ui, sans-serif",
    mono: "Menlo, monospace",
    archivo: "Archivo, system-ui, sans-serif",
  },
  colors,
  sizes: {
    gutter: {
      sm: "10px",
      md: "20px",
    },
    layout: {
      pageHeader: "60px",
      contentHeader: "65px",
      stickyHeaderTop: "50px",
      stickyContentTop: "135px",
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.8rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.875rem",
    "5xl": "2.25rem",
    "6xl": "3rem",
    "7xl": "3.75rem",
    "8xl": "4.5rem",
    "9xl": "6rem",
    "10xl": "8rem",
  },
  components: {
    Alert,
    Badge,
    Button: Button as any,
    Card,
    Modal,
    Spinner: Spinner as any,
    Tabs,
    Text,
    ...Form,
  },
});
