import {
  LoanRequestDerivedStatus,
  LoanRequestInterval,
  LoanRequestStatus,
} from "@obtainly-v2/enums";
import { LoanRequestModel } from "@obtainly-v2/schema";
import {
  addDays,
  differenceInDays,
  fromUnixTime,
  isAfter,
  startOfToday,
} from "date-fns";
import pluralize from "pluralize";
import { formatUnixTime } from "./date";

const intervalDays: Record<LoanRequestInterval, number> = {
  [LoanRequestInterval.Weekly]: 7,
  [LoanRequestInterval.Monthly]: 30,
};

export function getInterval(duration: number): LoanRequestInterval {
  const isWeekly = duration % 7 === 0 && duration <= 21;
  return isWeekly ? LoanRequestInterval.Weekly : LoanRequestInterval.Monthly;
}

export function getLoanRequestDueDate(loanRequest: LoanRequestModel): Date {
  return addDays(
    (loanRequest.activatedAt || loanRequest.createdAt) * 1000,
    loanRequest.duration - 1
  );
}

export function getLoanRequestDueDateText(
  loanRequest: LoanRequestModel
): string {
  return [
    LoanRequestStatus.Active,
    LoanRequestStatus.Paid,
    LoanRequestStatus.Unpaid,
  ].includes(loanRequest.status as LoanRequestStatus)
    ? formatUnixTime(
        getLoanRequestDueDate(loanRequest),
        "d MMM yyy"
      )?.toString() || ""
    : LoanRequestStatus.Rejected === loanRequest.status
    ? "Rejected"
    : "Pending";
}

export function getLoanRequestDueDateStatus(
  loanRequest: Partial<LoanRequestModel>
): {
  isOverDue: boolean;
  duration: number;
  elapsedDuration: number;
  extraDuration: number;
  totalDuration: number;
} {
  let output = {
    isOverDue: false,
    duration: loanRequest.duration || 0,
    extraDuration: 0,
    elapsedDuration: 0,
    totalDuration: loanRequest.duration || 0,
  };

  if (!loanRequest.activatedAt || !loanRequest.duration) return output;

  output.elapsedDuration = Math.abs(
    loanRequest.paidBackAt
      ? differenceInDays(
          fromUnixTime(loanRequest.activatedAt),
          fromUnixTime(loanRequest.paidBackAt)
        )
      : differenceInDays(startOfToday(), fromUnixTime(loanRequest.activatedAt))
  );

  // funding is overdue if
  // overdueRefDate is after activatedAt+duration
  // overdueRefDate = paidAt or startOfToday (if hasn't been paid)
  const overdueRefDate = loanRequest.paidBackAt
    ? fromUnixTime(loanRequest.paidBackAt)
    : startOfToday();
  const isOverDue = isAfter(
    overdueRefDate,
    addDays(fromUnixTime(loanRequest.activatedAt), loanRequest.duration)
  );

  if (isOverDue) {
    output.isOverDue = isOverDue;
    const totalDuration = differenceInDays(
      overdueRefDate,
      fromUnixTime(loanRequest.activatedAt)
    );
    output.extraDuration = totalDuration - loanRequest.duration;
    output.totalDuration = totalDuration;
  }

  return output;
}

export function getLoanRequestDurationFormat(
  loanRequest: Partial<LoanRequestModel>
): {
  unitCount: number;
  intervalCount: number;
  intervalUnit: string;
  suffix: string;
  rate: number;
  ratePerInterval: number;
} {
  let result = {
    unitCount: 0,
    intervalCount: 0,
    intervalUnit: "",
    suffix: "",
    rate: 0,
    ratePerInterval: 0,
  };

  if (!loanRequest.duration || !loanRequest.interest) return result;

  const interval = getInterval(loanRequest.duration);
  const intervalUnit = interval.replaceAll("ly", "");

  let intervalCount = loanRequest.duration / intervalDays[interval];
  if (!Number.isInteger(intervalCount)) {
    intervalCount = Number(intervalCount.toFixed(1));
  }

  const rate = loanRequest.interest;
  let ratePerInterval = rate / intervalCount;
  if (!Number.isInteger(ratePerInterval)) {
    ratePerInterval = Number(ratePerInterval.toFixed(2));
  }

  result.unitCount = loanRequest.duration;
  result.intervalCount = intervalCount;
  result.intervalUnit = intervalUnit;
  result.suffix = pluralize(intervalUnit, intervalCount);
  result.rate = rate;
  result.ratePerInterval = ratePerInterval;

  return result;
}

export function getInterestValue(amount: number, interestRate: number): number {
  return amount * (interestRate / 100);
}

export function getPaybackValues(loanRequest: Partial<LoanRequestModel>) {
  let output = {
    totalInterest: 0,
    preMaturedInterest: 0,
    eligibleCashBack: 0,
    penalty: 0,
  };

  // check all variables exists
  if (
    !loanRequest.amount ||
    !loanRequest.interest ||
    !loanRequest.duration ||
    !loanRequest.cashBackPercentage ||
    !loanRequest.maxCashBackPercentage
  ) {
    return output;
  }

  const totalInterestValue = getInterestValue(
    loanRequest.amount,
    loanRequest.interest
  );

  const dailyInterestValue = totalInterestValue / loanRequest.duration;
  const { elapsedDuration, extraDuration, isOverDue } =
    getLoanRequestDueDateStatus(loanRequest);
  const remainingDuration = loanRequest.duration - elapsedDuration;
  const remainingInterestValue =
    dailyInterestValue * Math.max(remainingDuration, 0);

  const cashBack =
    (remainingInterestValue * loanRequest.cashBackPercentage) / 100;
  const maxCashBack =
    (totalInterestValue * loanRequest.maxCashBackPercentage) / 100;

  const eligibleCashBack =
    remainingDuration > 0 ? Math.min(cashBack, maxCashBack) : 0;

  if (isOverDue && loanRequest.penaltyRate) {
    output.penalty = loanRequest.penaltyRate * extraDuration;
  }

  return {
    ...output,
    totalInterest: totalInterestValue,
    preMaturedInterest: remainingInterestValue,
    eligibleCashBack,
  };
}

export function getLoanRequestDerivedStatus(
  loanRequest: LoanRequestModel
): LoanRequestDerivedStatus | undefined {
  if (
    loanRequest?.expiredAt &&
    isAfter(
      loanRequest.paidBackAt
        ? fromUnixTime(loanRequest.paidBackAt)
        : new Date(),
      fromUnixTime(loanRequest.expiredAt)
    )
  ) {
    return LoanRequestDerivedStatus.Overdue;
  }
  return;
}
