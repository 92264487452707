import axios from "axios";
import { UserType } from "hooks/useAuth";

export interface HttpResponse<T = string> {
  data?: T;
}

const TOKEN_KEY = "token";
const USER_TYPE_KEY = "token_type";

const storage = typeof window === "undefined" ? null : localStorage;

export const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}`,
});

api.interceptors.request.use(
  (config) => {
    const { token } = getAccessToken();
    if (token && config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export function getAccessToken() {
  const token = storage?.getItem(TOKEN_KEY);
  const userType = storage?.getItem(USER_TYPE_KEY) as null | UserType;
  return {
    token,
    userType,
  };
}

export function setAccessToken(token?: string, userType?: UserType) {
  if (token) {
    storage?.setItem(TOKEN_KEY, token);
  } else {
    storage?.removeItem(TOKEN_KEY);
  }

  if (userType) {
    storage?.setItem(USER_TYPE_KEY, userType);
  } else {
    storage?.removeItem(USER_TYPE_KEY);
  }
}
