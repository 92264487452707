import { LoanRequestModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class LoanRequestService extends ModelService<LoanRequestModel> {
  constructor() {
    super("loan_request");
  }

  createWithOtp = (payload: {
    params: Partial<LoanRequestModel>;
    otp: string;
  }) => {
    const { params, otp } = payload;
    return this.api
      .post<{ status: string; message: string; data: LoanRequestModel }>(
        `/${this.modelName}/create?otp=${otp}`,
        params
      )
      .then(({ data }) => data);
  };

  updateWithOtp = (payload: {
    params: Partial<LoanRequestModel>;
    otp: string;
  }) => {
    const { params, otp } = payload;
    const { _id } = params as any;

    return this.api
      .put<{ status: string; message: string }>(
        `/${this.modelName}/update?_id=${_id}&otp=${otp}`,
        params
      )
      .then(({ data }) => data);
  };
}

export const loanRequestService = new LoanRequestService();
