import { ModelService } from "./Model";

class VerifyService extends ModelService<{}> {
  constructor() {
    super("verify");
  }

  bvn = (payload: { identifier: string; options: string }) => {
    return this.api
      .post(`/${this.modelName}/bvn`, payload)
      .then(({ data }) => data);
  };
}

export const verifyService = new VerifyService();
