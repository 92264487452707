import Image from "next/image";

export const Logo = ({ variant }: { variant?: "icon" }) => {
  return (
    <Image
      src="/icons/icon256.png"
      alt="Obtainly logo"
      width={40}
      height={40}
    />
  );
};
