export enum AccountType {
  CAC = "cac",
  NUBAN = "nuban",
  DNUBAN = "dnuban",
}

export enum AccountStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum AccountLedger {
  Principal = "principal",
  Interest = "interest",
  Pending = "pending",
  Penalty = "penalty",
}

export enum AccountStatementStatus {
  All = "",
  Funding = "funding",
  PayIn = "pay-in",
  CashBackOut = "cash-back-out",
  CashBackIn = "cash-back-in",
  PayOut = "pay-out",
  PayBack = "pay-back",
}

export enum UserAccount {
  User = "user",
  Admin = "admin",
}
