import { StackProps, VStack } from "@chakra-ui/react";
import React from "react";

export type ListProps<Item = any> = StackProps & {
  items: Item[];
  renderItem: (item: Item) => JSX.Element;
};

export const List: React.FC<ListProps> = ({
  items,
  renderItem,
  ...stackProps
}) => {
  return (
    <VStack spacing={0} {...stackProps}>
      {items?.length ? items.map((item) => renderItem(item)) : null}
      {/* TODO: Show pages */}
    </VStack>
  );
};
