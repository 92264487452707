import { SettlementProvider, SettlementStatus } from "@obtainly-v2/enums";
import { SettlementModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class SettlementService extends ModelService<{}> {
  constructor() {
    super("settlement");
  }

  readByStatus = (status: string) => {
    return this.api
      .post<{ data: Array<SettlementModel> }>(
        `/${this.modelName}/read_by_status`,
        {
          status,
        }
      )
      .then(({ data }) => data.data);
  };

  readByOwnerStatus = (
    owner: string,
    status: SettlementStatus = SettlementStatus.Pending
  ) => {
    return this.api
      .post<{ data: Array<SettlementModel> }>(
        `/${this.modelName}/read_by_owner_status`,
        {
          owner,
          status,
        }
      )
      .then(({ data }) => data.data);
  };

  actualPayout = (
    inboundRef: string,
    settlementId: string,
    commit: boolean,
    provider: SettlementProvider
  ) => {
    return this.api
      .post<{ data: Array<SettlementModel> }>(
        `/${this.modelName}/actual_payout`,
        {
          inbound_ref: inboundRef,
          settlement_id: settlementId,
          commit,
          provider,
        }
      )
      .then(({ data }) => data.data);
  };
}

export const settlementService = new SettlementService();
