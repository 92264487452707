export enum LoanRequestStatus {
  All = "",
  Pending = "pending",
  Approved = "approved",
  Active = "active",
  Paid = "paid",
  Unpaid = "unpaid",
  Rejected = "rejected",
}

export enum LoanRequestDerivedStatus {
  Due = "due",
  Overdue = "overdue",
  Bad = "bad",
}

export enum LoanRequestInterval {
  Weekly = "weekly",
  Monthly = "monthly",
}
