import { LedgerHead, LedgerSubhead } from "@obtainly-v2/enums";
import { LedgerModel } from "@obtainly-v2/schema";
import { getUnixTime, subDays } from "date-fns";
import { ModelService } from "./Model";

class LedgerService extends ModelService<{}> {
  constructor() {
    super("ledger");
  }

  statement = (
    owner: string,
    options?: {
      head?: LedgerHead;
      subhead?: LedgerSubhead;
      from_timestamp?: number;
      to_timestamp?: number;
    }
  ) => {
    return this.api
      .post<{ data: { ledger: LedgerModel; ledger_logs: any } }>(
        `/${this.modelName}/statement`,
        {
          owner,
          head: LedgerHead.Obtainly,
          subhead: LedgerSubhead.Balance,
          from_timestamp: getUnixTime(subDays(new Date(), 30)), // 1 month ago
          to_timestamp: 0,
          ...options,
        }
      )
      .then(({ data }) => data.data);
  };
}

export const ledgerService = new LedgerService();
