import {
  Box,
  BoxProps,
  Flex,
  Text,
  useClipboard,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";

interface CodeBoxProps extends BoxProps {
  value: string;
  forceExpanded?: boolean;
}

export const CodeBox: React.FC<CodeBoxProps> = ({ value, forceExpanded }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { onCopy, hasCopied } = useClipboard(value, 3000);
  const [expanded, setExpanded] = useState<boolean>(false);

  useOutsideClick({
    ref: containerRef,
    handler: () => {
      setExpanded(false);
    },
  });

  const finalExpanded = forceExpanded || expanded;

  return value ? (
    <Box position="relative" py={finalExpanded ? "20px" : "0"}>
      <Box
        ref={containerRef}
        border="1px solid"
        bg={finalExpanded ? "white" : "transparent"}
        boxShadow={finalExpanded ? "sm" : "none"}
        height={finalExpanded ? "auto" : "40px"}
        borderColor="gray.100"
        borderRadius="md"
        overflow="hidden"
        top={0}
        left={0}
        position={
          expanded && !finalExpanded
            ? "absolute"
            : expanded
            ? "absolute"
            : "static"
        }
        minW={finalExpanded ? "180px" : "80px"}
        maxW={finalExpanded ? "400px" : "150px"}
        zIndex={finalExpanded ? 1 : 0}
      >
        <Flex
          px="10px"
          position={"absolute"}
          width={finalExpanded ? "auto" : "100%"}
          right={finalExpanded ? "0px" : undefined}
          bg={
            finalExpanded
              ? "transparent"
              : "linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.6) 100%)"
          }
          height="40px"
          borderRadius="md"
          alignItems="center"
          justifyContent={finalExpanded ? "space-between" : "end"}
          gap={finalExpanded ? "6px" : undefined}
        >
          <Text></Text>
          {finalExpanded && (
            <Text
              fontSize="11px"
              bg={`rgba(255, 255, 255, 1)`}
              border="1px solid"
              borderColor="gray.50"
              px="4px"
              color="gray.700"
              boxShadow="md"
              cursor="pointer"
              onClick={() => onCopy()}
            >
              {hasCopied ? "Copied!" : "Copy"}
            </Text>
          )}
          <Text
            fontSize="11px"
            bg={`rgba(255, 255, 255, ${finalExpanded ? "1" : "0.9"})`}
            border="1px solid"
            borderColor="gray.50"
            px="4px"
            color="gray.700"
            boxShadow={finalExpanded ? "md" : "none"}
            cursor="pointer"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {finalExpanded ? "Collapse" : "Expand"}
          </Text>
        </Flex>
        <Box
          fontSize="xs"
          color="gray.800"
          fontFamily="monospace"
          p="10px"
          maxH={finalExpanded ? "250px" : "auto"}
          overflow={finalExpanded ? "auto" : "hidden"}
        >
          <pre>
            <code>{value}</code>
          </pre>
        </Box>
      </Box>
    </Box>
  ) : null;
};
