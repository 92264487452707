import { Box, Button, Flex, Text, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { userAuthService } from "services";
import * as Yup from "yup";
import { FormField, FormGroup, PasswordValidator } from "../common";

const REDIRECT_URL = `${process.env.NEXT_PUBLIC_APP_URL}/reset`;

const requestSchema = Yup.object({
  email: Yup.string().email().required().label("Email"),
  redirect_url: Yup.string().required(),
});

const resetSchema = Yup.object({
  token: Yup.string().required().label("Token"),
  password: Yup.string()
    .required()
    .min(8)
    .matches(/[a-z]/, "Password must contain one lowercase letter")
    .matches(/[A-Z]/, "Password must contain one uppercase letter")
    .matches(/[0-9]/, "Password must contain one number")
    .label("Password"),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .label("Password confirmation"),
});

interface PasswordResetProps {
  token?: string;
  onSuccess?: () => void;
}

export const PasswordReset: React.FC<PasswordResetProps> = ({
  token,
  onSuccess,
}) => {
  const { toast } = useToast();
  const [requestSentTo, setRequestSentTo] = useState<string>();

  const resetRequestForm = useForm<Yup.InferType<typeof requestSchema>>({
    resolver: yupResolver(requestSchema),
    defaultValues: {
      email: "",
      redirect_url: REDIRECT_URL,
    },
    mode: "all",
  });

  const resetForm = useForm<Yup.InferType<typeof resetSchema>>({
    resolver: yupResolver(resetSchema),
    defaultValues: { token, password: "", password_confirmation: "" },
    mode: "all",
  });

  const requestReset = useMutation(userAuthService.requestPasswordReset);
  const resetPassword = useMutation(userAuthService.resetPassword);

  const handleResetRequest = (data: Yup.InferType<typeof requestSchema>) => {
    requestReset.mutate(data, {
      onSuccess: (response) => {
        toast({
          description:
            response.message ||
            `Your request has been sent. Kindly check your email(${data.email})`,
          status: "success",
        });
        setRequestSentTo(data.email);
      },
      onError: (error: any) => {
        toast({
          description:
            error?.response?.data?.message || "An unknown error occurred.",
          status: "error",
        });
      },
    });
  };

  const handlePasswordReset = (data: Yup.InferType<typeof resetSchema>) => {
    resetPassword.mutate(data, {
      onSuccess: () => {
        onSuccess?.();
        toast({
          description: "Your password has been successfully reset!",
          status: "success",
        });
      },
      onError: (error: any) => {
        toast({
          description:
            error?.response?.data?.message || "An unknown error occurred.",
          status: "error",
        });
      },
    });
  };

  return requestSentTo ? (
    <Flex>
      <Text>
        We have sent an email to{" "}
        <Text as="span" fontWeight="medium">
          {requestSentTo}
        </Text>
        . Click on the link in the email to change your password.
      </Text>
    </Flex>
  ) : token ? (
    <form onSubmit={resetForm.handleSubmit(handlePasswordReset)}>
      <FormGroup label="Enter your new password">
        <FormField
          type="password"
          hasError={!!resetForm.formState.errors.password?.message}
          {...resetForm.register("password")}
        />
        {resetForm.formState.touchedFields.password &&
        resetForm.formState.errors.password?.message ? (
          <Box mt="8px" ml="15px">
            <PasswordValidator
              password={resetForm.watch("password")}
              validations={{
                length: true,
                uppercaseCharacter: true,
                lowercaseCharacter: true,
                number: true,
              }}
            />
          </Box>
        ) : null}
      </FormGroup>

      <FormGroup label="Confirm Password">
        <FormField
          type="password"
          errorMessage={
            resetForm.formState.errors.password_confirmation?.message
          }
          {...resetForm.register("password_confirmation")}
        />
      </FormGroup>

      <Button
        mt="10px"
        isLoading={resetPassword.isLoading}
        isDisabled={resetPassword.isLoading || !resetForm.formState.isValid}
        type="submit"
        colorScheme="primary"
        width="100%"
      >
        Set Password
      </Button>
    </form>
  ) : (
    <Box>
      <Text mb="10px">
        {`Enter the email address associated with your account and we'll send you
        a link to reset your password.`}
      </Text>

      <form onSubmit={resetRequestForm.handleSubmit(handleResetRequest)}>
        <VStack spacing="20px" alignItems="stretch">
          <FormGroup mb="0">
            <FormField
              type="email"
              autoComplete="email"
              placeholder="Enter your email address"
              errorMessage={resetRequestForm.formState.errors.email?.message}
              {...resetRequestForm.register("email")}
            />
          </FormGroup>

          <Button
            isLoading={requestReset.isLoading}
            disabled={
              requestReset.isLoading || !resetRequestForm.formState.isValid
            }
            type="submit"
            colorScheme="primary"
            data-test="submit-reset"
          >
            Continue
          </Button>
        </VStack>
      </form>
    </Box>
  );
};
