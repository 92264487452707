import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import NextImage from "next/image";
import { FC, Fragment, useRef, useState } from "react";
import { RiEyeLine } from "react-icons/ri";
import { FilePreviewer, FileSelector } from "./";
import DefaultImageIcon from "./img-icon.svg";
import PDFIcon from "./pdf-icon.svg";

interface FileItemProps {
  label?: string;
  dataUrl: string;
  onChange: (file: File) => void;
  onDelete?: () => Promise<void>;
}

export const FileItem: FC<FileItemProps> = (props) => {
  const { dataUrl, label, onChange, onDelete } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const fileSelectorRef = useRef<HTMLInputElement | null>(null);
  const [deleting, setDeleting] = useState(false);

  const openFileExplorer = () => {
    fileSelectorRef.current?.click();
  };

  const handleChange = (fileSet: Map<string, File>) => {
    if (fileSet.size) {
      onChange(Array.from(fileSet.values())[0]);
    }
  };

  const handleDelete = () => {
    setDeleting(true);
    onDelete?.().finally(() => setDeleting(false));
  };

  return (
    <Fragment>
      <FilePreviewer isOpen={isOpen} onClose={onClose} data={dataUrl} />
      <FileSelector
        ref={fileSelectorRef}
        onSelect={handleChange}
        multiple={false}
      />
      <Box
        display="block"
        borderRadius="md"
        overflow="hidden"
        bg="gray.50"
        mb="6px"
      >
        <Grid
          gap={2}
          templateColumns="auto 1fr auto"
          templateRows="1fr"
          alignItems="center"
        >
          {/* avatar */}
          <GridItem>
            {!!dataUrl && (
              <Box position="relative" cursor="zoom-in" onClick={onOpen}>
                <Box position="relative" boxSize="60px">
                  <Image
                    alt="file-icon"
                    as={NextImage}
                    layout="fill"
                    objectFit="cover"
                    src={dataUrl}
                    fallbackSrc={
                      dataUrl.slice(dataUrl.lastIndexOf(".")) === ".pdf"
                        ? PDFIcon
                        : DefaultImageIcon
                    }
                  />
                </Box>
                <Box
                  position="absolute"
                  top="0px"
                  left="0px"
                  boxSize="32px"
                  bgGradient="linear(to-tl, transparent, transparent, white)"
                >
                  <Center boxSize="18px" fontSize="14px" color="gray.900">
                    <RiEyeLine />
                  </Center>
                </Box>
              </Box>
            )}
          </GridItem>

          {/* file name */}
          <GridItem>
            <Text wordBreak="break-all" noOfLines={1}>
              {label || dataUrl}
            </Text>
            {!!label && (
              <Text
                wordBreak="break-all"
                noOfLines={1}
                fontSize="xs"
                color="gray.700"
              >
                {dataUrl.slice(-50)}
              </Text>
            )}
          </GridItem>

          <GridItem pr="10px">
            {/* action buttons */}
            <Flex justify={{ lg: "end", sm: "start" }} gap={2} flexWrap="wrap">
              <Button
                size="xs"
                colorScheme="primary"
                onClick={openFileExplorer}
                disabled={deleting}
              >
                Change
              </Button>
              {!!onDelete && (
                <Button
                  size="xs"
                  colorScheme="critical"
                  isLoading={deleting}
                  disabled={deleting}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Fragment>
  );
};
