import { BusinessModel, UserModel } from "@obtainly-v2/schema";
import { AuthService } from "./Auth";

class UserAuthService extends AuthService<UserModel> {
  constructor() {
    super("auth");
  }

  register = (payload: {
    business: Pick<BusinessModel, "name" | "type" | "status">;
    user: Pick<
      UserModel,
      | "firstName"
      | "lastName"
      | "email"
      | "phone"
      | "role"
      | "password"
      | "promptPasswordChange"
    >;
  }) => {
    return this.api.post(`/${this.modelName}/register`, payload);
  };
}

export const userAuthService = new UserAuthService();
