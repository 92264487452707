import React from "react";

import {
  Avatar,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Grid,
  GridItem,
  Text,
  VisuallyHiddenInput,
} from "@chakra-ui/react";
import { UploadModel } from "@obtainly-v2/schema/src/upload";
import { useFileUploadMutation, useToast } from "hooks";
import {
  BsFileEarmark,
  BsFileEarmarkImage,
  BsFileEarmarkPdf,
} from "react-icons/bs";
import FilePreviewer from "./FilePreviewer";

interface FileUploadFieldProps {
  label?: string;
  businessId?: string | null;
  tag?: string;
  fileUrl?: string;
  autoStartUpload?: boolean;
  onSuccess?: (data: UploadModel) => void;
  onDelete?: () => Promise<any>;
  readOnly?: boolean;
}

export const FileUploadField: React.FC<FileUploadFieldProps> = ({
  label,
  businessId,
  tag,
  fileUrl,
  autoStartUpload,
  onSuccess,
  onDelete,
  readOnly,
}) => {
  const { toast } = useToast();
  const allowedTypes = ["jpeg", "jpg", "png", "pdf"];
  const fileInput = React.useRef<any>();
  const componentKey = React.useId();

  const [uploadData, setUploadData] = React.useState<{ file: any }>();
  const [imagePreview, setImagePreview] = React.useState<any>();

  const getFileType = (file: any) =>
    file.type.split("/").pop()?.toLowerCase() || "";

  const fileType = React.useMemo<string | null>(() => {
    return uploadData?.file
      ? getFileType(uploadData.file)
      : typeof fileUrl === "string"
      ? allowedTypes.find(
          (ext) => ext === fileUrl?.split(".")?.pop()?.toLowerCase()
        )
      : "";
  }, [uploadData, fileUrl]); // eslint-disable-line

  const FileIcon = fileType
    ? fileType === "pdf"
      ? BsFileEarmarkPdf
      : BsFileEarmarkImage
    : BsFileEarmark;

  const [uploadError, setUploadError] = React.useState<boolean>(false);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const { mutate: mutateFileUpload, progress } =
    useFileUploadMutation<UploadModel>();

  const startUpload = ({ file }: { file: File }) => {
    setIsUploading(true);
    setUploadError(false);

    mutateFileUpload(
      {
        file: file || uploadData?.file,
        fileData: {
          name: `${tag}-${file.name.split("-").join("_")}`,
          businessId,
        },
        uploadUrl: "/uploads/upload/create",
      },
      {
        onSuccess: async ({ data }) => {
          onSuccess?.(data);
          setUploadData(undefined);
        },
        onError: ({ message }) => {
          setUploadError(true);
          toast({
            description: message ?? "Failed to upload",
            status: "error",
          });
        },
        onSettled: () => setIsUploading(false),
      }
    );
  };

  const startDelete = () => {
    setIsUploading(true);
    onDelete?.()
      .catch(({ message }) => {
        toast({
          description: message ?? "Failed to remove",
          status: "error",
        });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const reset = () => {
    setUploadData(undefined);
    setImagePreview(undefined);
    setUploadError(false);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files?.[0];

    if (!file) return;
    reader.onloadend = async () => {
      const type = getFileType(file);

      if (!allowedTypes.includes(type)) {
        toast({
          description: "Please select a valid image or PDF file.",
          status: "error",
        });
      } else if (file.size > 4024000) {
        toast({
          description: "Max Upload size is 4MB only.",
          status: "error",
        });
      } else {
        setUploadError(false);
        setImagePreview(reader.result);
        setUploadData({ file });
        if (autoStartUpload) {
          startUpload({ file });
        }
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <React.Fragment key={componentKey}>
      <VisuallyHiddenInput
        type="file"
        className="hidden-input"
        accept="image/jpg, image/jpeg, image/png, application/pdf"
        onChange={handleFileInputChange}
        ref={fileInput}
      />
      {(!!uploadData && uploadData.file) || !!fileUrl ? (
        <Box
          display="block"
          px="12px"
          py="8px"
          borderRadius="md"
          overflow="hidden"
          bg={
            progress
              ? `linear-gradient(90deg, #F0FFF5 ${progress}%, #F9FAFB ${
                  100 - progress
                }%)`
              : "gray.50"
          }
        >
          <Grid
            gap={2}
            templateColumns={{
              lg: "auto 1fr auto",
              base: "auto 1fr",
            }}
            templateRows={{ lg: "1fr", base: "auto auto" }}
            alignItems="center"
          >
            {/* avatar */}
            <GridItem rowSpan={{ lg: 1, base: 2 }}>
              <Center>
                {progress ? (
                  <CircularProgress
                    size="20px"
                    thickness="8px"
                    color="success.500"
                    trackColor="transparent"
                    isIndeterminate
                  />
                ) : (
                  <Avatar
                    size={{ lg: "xs", base: "xs" }}
                    icon={<FileIcon />}
                    color="white"
                    bgColor={uploadError ? "critical.500" : "success.500"}
                    boxShadow="0px 1px 0px #276749"
                  />
                )}
              </Center>
            </GridItem>

            {/* file name */}
            <GridItem>
              <Text wordBreak="break-all" noOfLines={1}>
                {uploadData?.file?.name ||
                  label ||
                  fileUrl ||
                  "No file selected"}
              </Text>
            </GridItem>

            <GridItem>
              {progress ? (
                // progress text
                <Text>
                  {progress === 100
                    ? "Saving..."
                    : `Uploading... (${progress}%)`}
                </Text>
              ) : (
                // action buttons
                <Flex
                  justify={{ lg: "end", sm: "start" }}
                  gap={2}
                  flexWrap="wrap"
                >
                  <FilePreviewer
                    data={imagePreview || fileUrl}
                    buttonLabel="View"
                    buttonProps={{
                      size: "sm",
                      variant: "link",
                      colorScheme: "primary",
                      disabled: isUploading,
                    }}
                  />
                  {((!!uploadData?.file && !!uploadError && !readOnly) ||
                    (!!fileUrl && !readOnly)) && (
                    <Button
                      size="sm"
                      variant="link"
                      colorScheme="success"
                      onClick={() => fileInput.current.click()}
                      isDisabled={isUploading}
                    >
                      Change
                    </Button>
                  )}
                  {!!uploadData && uploadData.file && (
                    <Button
                      size="sm"
                      variant="link"
                      colorScheme={uploadError ? "critical" : "success"}
                      onClick={() => startUpload(uploadData)}
                      isDisabled={isUploading}
                      isLoading={isUploading}
                      loadingText="Saving"
                    >
                      {uploadError ? "Retry" : "Upload"}
                    </Button>
                  )}
                  {!!onDelete && (
                    <Button
                      size="sm"
                      variant="link"
                      colorScheme="critical"
                      onClick={startDelete}
                      isDisabled={isUploading}
                    >
                      Delete
                    </Button>
                  )}
                </Flex>
              )}
            </GridItem>
          </Grid>
        </Box>
      ) : (
        <Button
          size="md"
          variant="outline"
          onClick={() => fileInput.current.click()}
          isDisabled={isUploading}
        >
          {fileUrl ? "Change Document" : `Upload ${label}`}
        </Button>
      )}
    </React.Fragment>
  );
};

export default FileUploadField;
