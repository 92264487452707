export const Alert = {
  parts: ["container"],
  baseStyle: {
    borderRadius: "md",
  },
  variants: {
    subtle: {
      borderRadius: "md",
    },
  },
};
