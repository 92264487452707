import { AdministratorModel, UserModel } from "@obtainly-v2/schema";
import { atom, SetStateAction, useAtom } from "jotai";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { adminAuthService, userAuthService } from "services";
import { getAccessToken, setAccessToken } from "utils";

export enum UserType {
  User = "user",
  Admin = "admin",
}

export type AuthAtom = {
  userType: null | UserType;
  isLoading: boolean;
  user: null | UserModel;
  admin: null | AdministratorModel;
};

const { userType } = getAccessToken();

const initialAuthState: AuthAtom = {
  userType: null,
  user: null,
  admin: null,
  isLoading: false,
};

export const authState = atom<AuthAtom>({
  ...initialAuthState,
  userType,
  isLoading: true,
});

export const isAuthenticatedState = atom((get) => {
  return !!get(authState).user || !!get(authState).admin;
});

export function useAuth(): {
  auth: AuthAtom;
  setAuth: (action: SetStateAction<AuthAtom>) => void;
  isAuthenticated: boolean;
  logout: () => void;
} {
  const queryCache = useQueryClient();
  const [auth, setAuth] = useAtom(authState);
  const [isAuthenticated] = useAtom(isAuthenticatedState);

  const onRefreshSuccess = (data: any) => {
    setAccessToken(data.data.accessToken, auth.userType!);
  };

  // Fetch admin user
  const {
    data: admin,
    isError: isErrorAdmin,
    isLoading: isLoadingAdmin,
  } = useQuery("admin", adminAuthService.user, {
    enabled: auth.userType === UserType.Admin,
    retry: false,
    retryOnMount: false,
  });
  useEffect(() => {
    if (!isLoadingAdmin) {
      if (isErrorAdmin) {
        setAuth((state) => ({
          ...state,
          ...initialAuthState,
        }));
      } else if (admin) {
        setAuth((state) => ({
          ...state,
          admin,
          user: null,
          isLoading: false,
        }));
      }
    }
  }, [setAuth, admin, isErrorAdmin, isLoadingAdmin]);

  // Refresh admin token
  useQuery("admin_token_refresh", adminAuthService.refresh, {
    enabled: isAuthenticated && auth.userType === UserType.Admin,
    refetchOnWindowFocus: "always",
    refetchInterval: 300000, // 5 mins
    onSuccess: onRefreshSuccess,
  });

  // Fetch user user ;)
  const {
    data: user,
    isError: isErrorUser,
    isLoading: isLoadingUser,
  } = useQuery("user", userAuthService.user, {
    enabled: auth.userType === UserType.User,
    retry: false,
    retryOnMount: false,
  });
  useEffect(() => {
    if (!isLoadingUser) {
      if (isErrorUser) {
        setAuth((state) => ({
          ...state,
          ...initialAuthState,
        }));
      } else if (user) {
        setAuth((state) => ({
          ...state,
          user,
          admin: null,
          isLoading: false,
        }));
      }
    }
  }, [setAuth, user, isErrorUser, isLoadingUser]);

  // Refresh user token
  useQuery("user_token_refresh", userAuthService.refresh, {
    enabled: isAuthenticated && auth.userType === UserType.User,
    refetchOnWindowFocus: "always",
    refetchInterval: 300000, // 5 mins
    onSuccess: onRefreshSuccess,
  });

  useEffect(() => {
    if (!auth.userType) {
      setAuth((state) => ({ ...state, isLoading: false }));
    }
  }, [setAuth, auth.userType]);

  return {
    auth,
    setAuth,
    isAuthenticated,
    logout: () => {
      setAccessToken();
      setAuth(initialAuthState);
      queryCache.invalidateQueries();
    },
  };
}
