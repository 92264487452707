import { useTheme } from "@chakra-ui/react";
import isMobileDevice from "ismobilejs";
import lodash, { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";

export interface AutocompleteProps {
  name?: string;
  size?: "sm" | "md";
  value: any;
  options?: any[];
  isInvalid?: boolean;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
  onChange: (value: unknown) => void;
  isMulti?: boolean;
  isActive?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  isAsync?: boolean;
  menuPlacement?: "top" | "bottom" | "auto";
  onCreateOption?: (inputValue: string) => void;
}

const scrollOptions: ScrollIntoViewOptions = {
  behavior: "smooth",
  block: "start",
};

export const Autocomplete: React.FC<AutocompleteProps> = ({
  size,
  isAsync,
  isInvalid,
  onCreateOption,
  ...props
}) => {
  const { colors } = useTheme();

  const customStyles = {
    container: (styles: any) => ({
      ...styles,
      width: "100%",
    }),
    control: (styles: any) => ({
      ...styles,
      background: props.isDisabled ? colors.gray["50"] : "#fff",
      border: `1px solid ${
        isInvalid ? colors.critical["600"] : colors.gray["200"]
      }`,
      borderWidth: isInvalid ? "2px !important" : "1px !important",
      borderRadius: size === "sm" ? "6px" : "6px",
      paddingTop: size === "sm" ? "0" : "2px",
      paddingLeft: size === "sm" ? "3px" : "8px",
      transition: "all 0.3s",
      minHeight: size === "sm" ? "26px" : "42px",
      height: size === "sm" ? "34px" : "42px",
      minWidth: "180px",
      ":hover": {
        ...styles[":hover"],
        border: `1px solid ${colors.gray["400"]}`,
        cursor: "default",
      },
      ":focus": {
        ...styles[":focus"],
        border: `1px solid ${colors.primary["600"]}`,
        borderWidth: "1px",
      },
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      svg: {
        ...styles["svg"],
        color: colors.gray["400"],
        width: "18px",
        height: "18px",
        position: "relative",
        right: "1px",
      },
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "none",
    }),
    input: (styles: any) => ({
      ...styles,
      color: colors.black,
    }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: colors.gray["600"],
    }),
    menu: (styles: any) => ({
      ...styles,
      border: "none",
      borderRadius: "0px",
      boxShadow: "none",
      padding: "0px",
      backgroundColor: "transparent",
      marginBottom: "80px",
    }),
    menuList: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      border: `1px solid ${colors.gray["200"]}`,
      borderRadius: size === "sm" ? "6px" : "6px",
      boxShadow: "none",
      padding: "8px",
      marginBottom: "80px",
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: colors.gray["50"],
      margin: "0 2px 0",
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      padding: "3px 3px 2px",
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? colors.gray["100"]
        : isFocused
        ? colors.gray["50"]
        : null,
      borderRadius: "6px",
      marginBottom: "4px",
      color: isDisabled ? "#ccc" : colors.black,
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled &&
          (isSelected ? colors.primary["600"] : colors.gray["50"]),
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: colors.gray["600"],
      /** Adjust vertical position */
      top: "48%",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: colors.black,
      /** Adjust vertical position */
      top: "48%",
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flexWrap: "nowrap",
      padding: "1px 8px",
    }),
  };

  const styleProps = {
    theme: (theme: any) => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.primary["600"],
      },
    }),
    styles: customStyles,
  };

  const { options, ...asyncProps } = props;

  const selectedOption = React.useMemo(() => {
    return options?.find((option) => option?.value === props?.value) ?? null;
  }, [options, props?.value]);

  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  const throttled = useCallback(() => {
    return lodash.throttle(() => {
      const detectedMobile = isMobileDevice(window.navigator).any;
      if (focused && detectedMobile) {
        setTimeout(() => {
          containerRef.current?.scrollIntoView(scrollOptions);
        }, 500);
      }
    }, 2000);
  }, [focused]);

  useEffect(() => {
    const subscription = throttled();
    window?.visualViewport?.addEventListener("resize", subscription);
    return () =>
      window?.visualViewport?.removeEventListener("resize", subscription);
  }, [throttled]);

  return (
    <div ref={containerRef}>
      {isAsync ? (
        <AsyncSelect
          {...asyncProps}
          {...styleProps}
          value={selectedOption}
          loadOptions={debounce((query, callback) => {
            if (query?.trim?.()?.length > 2) {
              const results = options?.length
                ? options.filter((option) => {
                    return option.label.includes(query);
                  })
                : [];

              callback(results);
            }
          }, 250)}
          onFocus={handleFocus}
          onBlur={() => {
            props.onBlur?.();
            handleBlur();
          }}
        />
      ) : onCreateOption ? (
        <CreatableSelect
          onCreateOption={onCreateOption}
          {...props}
          {...styleProps}
          value={selectedOption}
          createOptionPosition="first"
          formatCreateLabel={(inputString) =>
            `Press ⏎ to create "${inputString}"`
          }
          onFocus={handleFocus}
          onBlur={() => {
            props.onBlur?.();
            handleBlur();
          }}
        />
      ) : (
        <Select
          {...props}
          {...styleProps}
          value={selectedOption}
          onFocus={handleFocus}
          onBlur={() => {
            props.onBlur?.();
            handleBlur();
          }}
        />
      )}
    </div>
  );
};

export default Autocomplete;
