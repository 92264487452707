import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { SettingTag } from "@obtainly-v2/enums";
import { useAuth } from "hooks";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { settingService } from "services";
import { appVersion } from "utils";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<any>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export const InstallPrompt: React.FC = () => {
  const [beforInstallPromptEvent, setBeforInstallPromptEvent] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [appInstalled, setAppInstalled] = React.useState<boolean>(false);
  const [ready, setReady] = useState(false);
  const { isAuthenticated } = useAuth();

  const { data: appVersionSetting, isLoading: isLoadingAppVersion } = useQuery(
    "setting.read_by_tag",
    () => settingService.readByTag(SettingTag.AppVersion),
    { enabled: isAuthenticated }
  );

  const installApp = async () => {
    try {
      const deferredPrompt = await beforInstallPromptEvent?.prompt();
      setAppInstalled(deferredPrompt.outcome === "accepted");
    } catch (error) {
      console.log("install error", error);
    }
  };

  const updateApp = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then(async function (reg) {
        if (reg) {
          // await reg.update()
          reg.unregister().then(function () {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      });
    } else {
      console.log("Service worker not supported.");
      window.location.reload();
    }
  };

  // TO-DO
  // detect app is already installed
  // prompt to open app
  // --- implementation ---
  // set related apps in manifest.json
  // get installed related apps and do comparison
  // window.navigator.getInstalledRelatedApps()
  // lastly, open app
  // window.open(window.location.href, '_blank')

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      // app was launched in standalone mode (pwa-mode)
      // don't show prompt
      setAppInstalled(true);
    } else if ("serviceWorker" in navigator) {
      window.addEventListener("beforeinstallprompt", (event) => {
        console.log("before install", event);
        setBeforInstallPromptEvent(event);
      });

      window.addEventListener("appinstalled", () => {
        console.log("app installed");
        // app was installed, no need to show prompt
        setAppInstalled(true);
      });

      navigator.serviceWorker
        .getRegistration()
        .then(async function (registeration) {
          if (registeration) {
            await registeration.update();
          }
        })
        .catch(function (error) {
          console.log(`Registration failed with ${error}`);
        });
    }

    setReady(true);

    return () => {
      window.removeEventListener("beforeinstallprompt", () => null);
      window.removeEventListener("appinstalled", () => null);
    };
  }, []);

  const installationRequired = !appInstalled && !!beforInstallPromptEvent;
  const updateRequired =
    typeof appVersionSetting?.value === "string" &&
    appVersionSetting.value !== appVersion;

  return ready && !isLoadingAppVersion ? (
    <>
      {installationRequired ? (
        <Box
          bgColor="primary.600"
          boxShadow="sm"
          width="100%"
          px="20px"
          py="10px"
        >
          <HStack justify="space-between" w="100%" gridGap={2}>
            <Text fontSize="sm" color="white">
              Install Obtainly app for free. It&apos;s fast and takes less
              storage on your device.
            </Text>
            <Box>
              <Button onClick={installApp} size="sm">
                Install
              </Button>
            </Box>
          </HStack>
        </Box>
      ) : updateRequired ? (
        <Box
          bgColor="primary.600"
          boxShadow="sm"
          width="100%"
          px="20px"
          py="10px"
        >
          <HStack justify="space-between" w="100%" gridGap={2}>
            <Text fontSize="sm" color="white">
              A new version of Obtainly is available. Update now for better
              experience.
            </Text>
            <Box>
              <Button onClick={updateApp} size="sm">
                Update
              </Button>
            </Box>
          </HStack>
        </Box>
      ) : null}
    </>
  ) : null;
};

export default InstallPrompt;
