import { ActivityLogModel, ActivityLogReadLogs } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class ActivityLogService extends ModelService<ActivityLogModel> {
  constructor() {
    super("activity");
  }

  readLog = (modelId: string, modelType: string) => {
    return this.api
      .get<{ data: ActivityLogReadLogs[] }>(
        `/${this.modelName}/read_logs?modelId=${modelId}&modelType=${modelType}`
      )
      .then(({ data }) => data.data);
  };
}

export const activityLogService = new ActivityLogService();
