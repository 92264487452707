import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: "999px",
    padding: "5px 12px",
    fontWeight: "medium",
    textTransform: "capitalize",
  },
};
