import { FormLabel } from "@chakra-ui/form-control";
import {
  Input,
  InputAddonProps,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/input";
import { Icon, Text } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { Textarea } from "@chakra-ui/textarea";
import { useScrollElementIntoView } from "hooks";
import isMobileDevice from "ismobilejs";
import React, {
  ElementType,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { Autocomplete } from "./Autocomplete";

export type FormFieldProps = Omit<InputProps, "as"> & {
  as?: "input" | "select" | "autocomplete" | "textarea";
  label?: string;
  errorMessage?: string;
  prepend?: ReactNode;
  prependProps?: InputAddonProps;
  append?: ReactNode;
  appendProps?: InputAddonProps;
  hasError?: boolean;
  [value: string]: unknown;
};

export const FormField = React.forwardRef<HTMLInputElement, FormFieldProps>(
  (componentProps, ref) => {
    const {
      as,
      label,
      errorMessage,
      prepend,
      prependProps,
      append,
      appendProps,
      children,
      hasError,
      ...props
    } = componentProps;
    const Tag: ElementType =
      as === "textarea"
        ? Textarea
        : as === "select"
        ? Select
        : as === "autocomplete"
        ? Autocomplete
        : Input;

    const [showPassword, setShowPassword] = useState(false);

    const detectedMobile = isMobileDevice(window.navigator).any;

    const internalRef = useRef(null);
    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => internalRef.current
    );
    useScrollElementIntoView(internalRef);

    return (
      <>
        {!!label && <FormLabel mb={1}>{label}</FormLabel>}

        <InputGroup size={props?.size}>
          {!!prepend && (
            <InputLeftAddon {...prependProps}>{prepend}</InputLeftAddon>
          )}

          <Tag
            ref={internalRef}
            {...props}
            type={
              props.type === "password" && showPassword ? "text" : props.type
            }
            borderColor={errorMessage || hasError ? "critical.100" : null}
            onFocus={(event: any) => {
              props?.onFocus?.(event);
              detectedMobile && event?.target?.scrollIntoView?.(true);
            }}
          >
            {children}
          </Tag>

          {!!append && (
            <InputRightAddon {...appendProps}>{append}</InputRightAddon>
          )}

          {props.type === "password" && (
            <InputRightElement>
              <Icon
                as={showPassword ? RiEyeOffLine : RiEyeLine}
                fontSize="20px"
                color="gray.400"
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          )}
        </InputGroup>

        {!!errorMessage && (
          <Text fontSize="sm" color="red.500" mt="2px">
            {errorMessage}
          </Text>
        )}
      </>
    );
  }
);

FormField.displayName = "FormField";
