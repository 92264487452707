import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Modal: ComponentStyleConfig = {
  parts: ["dialog", "modal", "content", "header", "footer", "closeButton"],
  baseStyle: {
    dialog: {
      width: "90% !important",
    },
    header: {
      fontSize: "lg",
      fontWeight: "normal",
      pt: "20px",
    },
    closeButton: {
      top: "12px",
    },
    body: {
      padding: "20px",
      maxHeight: "60vh",
      overflowY: "auto",
    },
    footer: {
      borderTop: "1px solid",
      borderColor: "gray.100",
    },
  },
};
