import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Progress,
  Text,
} from "@chakra-ui/react";
import { BusinessModel, UploadModel } from "@obtainly-v2/schema";
import { useFileUploadMutation } from "hooks";
import NextImage from "next/image";
import { FC, Fragment, useEffect, useState } from "react";
import { RiEyeLine } from "react-icons/ri";
import { getFileName, getFileType } from "utils";
import DefaultImageIcon from "./img-icon.svg";
import PDFIcon from "./pdf-icon.svg";

interface FileUploaderProps {
  label?: string;
  tag: string;
  file: File;
  onUpload: (uploadId: string) => Promise<void>;
  onUploadError?: (error: string) => void;
  onRemove?: VoidFunction;
  business?: BusinessModel;
  autoStart?: boolean;
}

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const {
    file,
    tag,
    label,
    autoStart,
    business,
    onUpload,
    onUploadError,
    onRemove,
  } = props;

  const [uploadError, setUploadError] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileDataUrl, setFileDataUrl] = useState<string>("");
  const [uploadId, setUploadId] = useState<string>("");

  const fileName = getFileName(file);
  const fileType = getFileType(file);

  const { mutate: mutateFileUpload, progress } =
    useFileUploadMutation<UploadModel>();

  const initiateUpload = () => {
    setIsUploading(true);
    setUploadError(false);
    mutateFileUpload(
      {
        file,
        fileData: {
          name: `${tag}-${fileName}`,
          businessId: business?._id,
        },
        uploadUrl: `/uploads/upload/create`,
      },
      {
        onSuccess: (response) => {
          const { data, message } = response;

          try {
            if (data) {
              setUploadId(data._id);
              onUpload(data._id)
                .then(() => {
                  setUploadId("");
                  onRemove?.();
                })
                .catch(() => setUploadError(true))
                .finally(() => setIsUploading(false));
            } else {
              throw new Error(message);
            }
          } catch (error: any) {
            setUploadError(true);
            onUploadError?.(error?.message || "Failed to upload");
            setIsUploading(false);
          }
        },
        onError: (error) => {
          setUploadError(true);
          setIsUploading(false);
          onUploadError?.(
            typeof error === "string"
              ? error
              : error.message || "Failed to upload"
          );
        },
        // onSettled: () => onRemove?.(),
      }
    );
  };

  const startUpload = () => {
    if (!!uploadId) {
      setIsUploading(true);
      setUploadError(false);
      onUpload(uploadId)
        .then(() => {
          setUploadId("");
          onRemove?.();
        })
        .catch(() => setUploadError(true))
        .finally(() => setIsUploading(false));
    } else {
      initiateUpload();
    }
  };

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      reader.readyState === FileReader.DONE &&
        setFileDataUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
    return () => reader.abort();
  }, [file]);

  useEffect(() => {
    !!autoStart && startUpload();
  }, [autoStart]); // eslint-disable-line

  return (
    <Fragment>
      <Box
        display="block"
        borderRadius="md"
        overflow="hidden"
        bg={
          uploadError
            ? "critical.100"
            : progress
            ? `linear-gradient(90deg, #F0FFF5 ${progress}%, #F9FAFB ${
                100 - progress
              }%)`
            : "gray.50"
        }
        mb="6px"
      >
        {!!progress && (
          <Progress
            value={progress}
            max={100}
            height="2px"
            colorScheme="success"
          />
        )}
        <Grid
          gap={2}
          templateColumns="auto 1fr auto"
          templateRows="1fr"
          alignItems="center"
        >
          {/* avatar */}
          <GridItem>
            {!!fileDataUrl && (
              <Box position="relative" cursor="zoom-in">
                <Box position="relative" boxSize="60px">
                  <Image
                    alt="file-upload-thumbnail"
                    as={NextImage}
                    layout="fill"
                    objectFit="cover"
                    src={fileDataUrl}
                    fallbackSrc={
                      fileType === "pdf" ? PDFIcon : DefaultImageIcon
                    }
                  />
                </Box>
                <Box
                  position="absolute"
                  top="0px"
                  left="0px"
                  boxSize="32px"
                  bgGradient="linear(to-tl, transparent, transparent, white)"
                  display="none"
                >
                  <Center boxSize="18px" fontSize="14px" color="gray.900">
                    <RiEyeLine />
                  </Center>
                </Box>
              </Box>
            )}
          </GridItem>

          {/* file name */}
          <GridItem>
            <Text wordBreak="break-all" noOfLines={1}>
              {label || fileName}
            </Text>
            {!!label && (
              <Text
                wordBreak="break-all"
                noOfLines={1}
                fontSize="xs"
                color="gray.700"
              >
                {fileName.slice(-50)}
              </Text>
            )}
          </GridItem>

          <GridItem pr="10px">
            {progress ? (
              // progress text
              <Text>
                {progress === 100 ? "Saving..." : `Uploading... (${progress}%)`}
              </Text>
            ) : (
              // action buttons
              <Flex
                justify={{ lg: "end", sm: "start" }}
                gap={2}
                flexWrap="wrap"
              >
                {!!file && (
                  <Button
                    size="xs"
                    colorScheme={uploadError ? "critical" : "success"}
                    onClick={() => startUpload()}
                    isDisabled={isUploading}
                    isLoading={isUploading}
                    loadingText="Saving"
                  >
                    {uploadError ? "Retry" : "Upload"}
                  </Button>
                )}
                {!!onRemove && (
                  <Button
                    size="xs"
                    colorScheme="blackAlpha"
                    onClick={() => onRemove()}
                    isDisabled={isUploading}
                    isLoading={isUploading}
                  >
                    {uploadError ? "Cancel" : "Remove"}
                  </Button>
                )}
              </Flex>
            )}
          </GridItem>
        </Grid>
      </Box>
    </Fragment>
  );
};
