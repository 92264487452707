import { useToast } from "hooks";
import { ChangeEvent, forwardRef, useState } from "react";
import { getFileName, getFileType, uploadableFileTypes } from "utils";
import { FileSelectorPreview } from "./FileSelectorPreview";

interface FileSelectorProps {
  onSelect: (fileSet: Map<string, File>) => void;
  multiple?: boolean;
}

export const FileSelector = forwardRef<any, FileSelectorProps>((props, ref) => {
  const { onSelect, multiple } = props;
  const { toast } = useToast();
  const [files, setFiles] = useState<Map<string, File>>(() => new Map());

  const handleFileInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    const files = event.target.files;
    const noOfFiles = files?.length;

    if (!noOfFiles) return;

    const fileDataList = new Map<string, File>();
    const readError = new Map<string, string>();

    for (const file of Array.from(files)) {
      if (!file) continue;
      const type = getFileType(file);
      const fileName = getFileName(file);

      if (!uploadableFileTypes.has(type)) {
        readError.set(fileName, "Please select a valid image or PDF file.");
      } else if (file.size > 4024000) {
        readError.set(fileName, "Max Upload size is 4MB only.");
      } else {
        fileDataList.set(fileName, file);
      }
    }
    if (readError.size > 1) {
      toast({ description: "Some files could not be added", status: "error" });
    }
    if (readError.size === 1) {
      toast({
        description: Array.from(readError.values())[0],
        status: "error",
      });
    }

    event.target.value = ""; // reset input element, else change events won't be triggered
    setFiles(fileDataList);
  };

  const onClose = () =>
    setFiles(() => {
      return new Map();
    });

  return (
    <>
      <input
        type="file"
        style={{
          display: "block",
          visibility: "hidden",
          opacity: 0,
          maxWidth: 0,
          maxHeight: 0,
        }}
        className="hidden-input"
        accept="image/jpg, image/jpeg, image/png, application/pdf"
        onChange={handleFileInputChange}
        ref={ref}
        multiple={multiple}
      />
      <FileSelectorPreview
        isOpen={files.size > 0}
        files={files}
        onClose={onClose}
        errors={new Map()}
        onSelectFiles={(selectedFiles) => {
          onSelect(selectedFiles);
          onClose();
        }}
      />
    </>
  );
});

FileSelector.displayName = "FileSelector";
