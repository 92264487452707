import {
  BusinessDetailStatus,
  BusinessDetailTag,
  CACRegistrationType,
} from "@obtainly-v2/enums";
import { BusinessDetailModel, BusinessModel } from "@obtainly-v2/schema";
import { useMemo } from "react";

const defaultRequiredDocuments: Set<BusinessDetailTag> = new Set([
  BusinessDetailTag.BVN,
  BusinessDetailTag.IdentityCard,
  BusinessDetailTag.Passport,
  BusinessDetailTag.UtilityBill,
  BusinessDetailTag.CACCertificate,
  BusinessDetailTag.RegType,
  BusinessDetailTag.CACStatus_Report,
]);
const otherRequiredDocuments: Record<
  CACRegistrationType,
  Set<BusinessDetailTag>
> = {
  [CACRegistrationType.Business]: new Set(),
  [CACRegistrationType.Company]: new Set([BusinessDetailTag.CACMemorandum]),
};

export function useProfileCompletion(business?: BusinessModel) {
  const result = useMemo(() => {
    const _details: Partial<Record<BusinessDetailTag, BusinessDetailModel>> =
      {};
    const aggregateRequiredDocuments = new Set(defaultRequiredDocuments);
    const registrationType = business?.details?.find(
      (detail) => detail.tag === BusinessDetailTag.RegType
    )?.value;

    if (
      registrationType &&
      otherRequiredDocuments?.[registrationType as CACRegistrationType]
    ) {
      otherRequiredDocuments[registrationType as CACRegistrationType].forEach(
        (tag) => aggregateRequiredDocuments.add(tag)
      );
    }

    const unset: Set<BusinessDetailTag> = new Set(aggregateRequiredDocuments);
    const unverified: Set<BusinessDetailTag> = new Set(
      aggregateRequiredDocuments
    );
    const verified: Set<BusinessDetailTag> = new Set();

    business?.details?.forEach((detail) => {
      _details[detail.tag] = detail;
      const isRequired = aggregateRequiredDocuments.has(detail.tag);
      if (isRequired) {
        if (detail?.status === BusinessDetailStatus.Verified) {
          verified.add(detail.tag);
          unverified.delete(detail.tag);
        }
        unset.delete(detail.tag);
      }
    });
    return {
      details: business?.details || [],
      detailsDict: _details,
      unverified,
      unverifiedCount: unverified.size,
      verified,
      verifiedCount: verified.size,
      unset,
      unsetCount: unset.size,
      requiredDocumentCount: aggregateRequiredDocuments.size,
      isComplete: aggregateRequiredDocuments.size === verified.size,
    };
  }, [business]);

  return result;
}
