export enum BusinessStatus {
  Active = "active",
  Inactive = "inactive",
  Suspended = "suspended",
}

export enum BusinessType {
  Vendor = "vendor",
  Client = "client",
  CreditProvider = "credit_provider",
}
