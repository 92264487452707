import { atom, SetStateAction, useAtom } from "jotai";
import { useEffect } from "react";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<any>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export enum UserType {
  User = "user",
  Admin = "admin",
}

export type InstallationAtom = {
  isReady: boolean;
  isInstalled: boolean;
  installEvent: null | BeforeInstallPromptEvent;
};

const initialInstallationState: InstallationAtom = {
  installEvent: null,
  isInstalled: false,
  isReady: false,
};

export const installationState = atom<InstallationAtom>({
  ...initialInstallationState,
});

export function useAppInstallation(): {
  installation: InstallationAtom;
  setInstallation: (action: SetStateAction<InstallationAtom>) => void;
  installApp: () => Promise<void>;
  updateApp: VoidFunction;
} {
  const [installation, setInstallation] = useAtom(installationState);

  const installApp = async () => {
    try {
      const deferredPrompt = await installation.installEvent?.prompt();
      setInstallation((state) => ({
        ...state,
        isInstalled: deferredPrompt.outcome === "accepted",
      }));
    } catch (error) {
      console.log("install error", error);
    }
  };

  const updateApp = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistration().then(async function (reg) {
        if (reg) {
          // await reg.update()
          reg.unregister().then(function () {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      });
    } else {
      console.log("Service worker not supported.");
      window.location.reload();
    }
  };

  // TO-DO
  // detect app is already installed
  // prompt to open app
  // --- implementation ---
  // set related apps in manifest.json
  // get installed related apps and do comparison
  // window.navigator.getInstalledRelatedApps()
  // lastly, open app
  // window.open(window.location.href, '_blank')

  useEffect(() => {
    async function startInstaller() {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        // app was launched in standalone mode (pwa-mode)
        // don't show prompt
        setInstallation((state) => ({ ...state, isInstalled: true }));
      } else if ("serviceWorker" in navigator) {
        window.addEventListener("beforeinstallprompt", (event) => {
          console.log("before install", event);
          setInstallation((state) => ({ ...state, installEvent: event }));
        });

        window.addEventListener("appinstalled", () => {
          console.log("app installed");
          // app was installed, no need to show prompt
          setInstallation((state) => ({ ...state, isInstalled: true }));
        });

        try {
          const registration = await navigator.serviceWorker.getRegistration();
          if (registration) {
            await registration.update();
          }
        } catch (error) {
          console.log(`Registration failed with ${error}`);
          setInstallation((state) => ({ ...state, isReady: true }));
        }
      }

      setInstallation((state) => ({ ...state, isReady: true }));
    }

    startInstaller();

    return () => {
      window.removeEventListener("beforeinstallprompt", () => null);
      window.removeEventListener("appinstalled", () => null);
    };
  }, [setInstallation]);

  return {
    installation,
    setInstallation,
    installApp,
    updateApp,
  };
}
