import { Button, HStack, StackProps } from "@chakra-ui/react";
import {
  Pagination as UnstyledPagination,
  PaginationItem as UnstyledPaginationItem,
  PaginationProps as UnstyledPaginationProps,
} from "@unstyled-react/pagination";

export type PaginationProps = UnstyledPaginationProps &
  Omit<StackProps, "onChange">;

export const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <UnstyledPagination
      as={HStack}
      spacing="10px"
      pageSize={10}
      justifyContent="center"
      itemLabels={{ first: false, last: false }}
      renderItem={(item) => (
        <UnstyledPaginationItem
          {...item}
          as={Button}
          size="sm"
          variant={item.active ? "solid" : "outline"}
          colorScheme={item.active ? "gray" : "gray"}
          borderRadius="md"
          transition="none"
        />
      )}
      {...props}
    />
  );
};
