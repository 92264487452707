import { ModelService } from "./Model";

class OtpService extends ModelService<{}> {
  constructor() {
    super("otp");
  }

  generate = () => {
    return this.api
      .post<{ message: string }>(`/${this.modelName}/generate`)
      .then(({ data }) => data);
  };
}

export const otpService = new OtpService();
