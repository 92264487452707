import { TableQueryProps } from "components/common/Table";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { decodeSortParams, encodeSortParams } from "utils";

interface UseTableSortProps extends TableQueryProps {}

export function useTableSort(defaults: UseTableSortProps) {
  const {
    query: { page: queryPage, sort: querySort },
    ...router
  } = useRouter();

  const [tableQueryProps, setTableQueryProps] =
    useState<TableQueryProps>(defaults);

  const updateTableQueryProps = useCallback(
    (props?: TableQueryProps) => {
      const { page, sort } = { ...tableQueryProps, ...props };
      router.replace({
        pathname: router.pathname,
        query: { page: page, sort: encodeSortParams(sort) },
      });
    },
    [router, tableQueryProps]
  );

  const initializeSorting = useCallback(() => {
    if (typeof queryPage === "string" && !isNaN(parseInt(queryPage))) {
      setTableQueryProps((prev) => {
        return { ...prev, page: Number(queryPage) };
      });
    }
    try {
      if (querySort && typeof querySort === "string") {
        const decoded = decodeSortParams(querySort);
        if (decoded.id) {
          setTableQueryProps((prev) => {
            return {
              ...prev,
              sort: decoded,
            };
          });
        }
      }
    } catch (error) {
      throw error;
    }
  }, [queryPage, querySort]);

  return {
    tableQueryProps,
    updateTableQueryProps,
    initializeSorting,
  };
}
