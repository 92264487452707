import { AccountStatus, AccountType } from "@obtainly-v2/enums";
import {
  AccountAuditLoanRequestPayload,
  AccountModel,
  AccountOwnerStatusResponse,
  AccountPenaltyLoanRequestPayload,
  AccountReconciliationPayload,
  AccountReconciliationVendorPayload,
} from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class AccountService extends ModelService<AccountModel> {
  constructor() {
    super("account");
  }

  readBanks = () => {
    return this.api
      .get<{
        data: Array<{
          name: string;
          slug: string;
          code: string;
          active: boolean;
        }>;
      }>(`/${this.modelName}/lookup_banks`)
      .then(({ data }) => data.data);
  };

  readByOwnerType = (owner?: string, type?: AccountType) => {
    return this.api
      .get<{ data: AccountModel[] }>(
        `/${this.modelName}/read_by_owner_type?owner=${owner}&type=${type}`
      )
      .then(({ data }) => data.data);
  };

  readByOwnerTypeStatus = (
    owner?: string,
    type?: AccountType,
    status?: "active" | "inactive"
  ) => {
    return this.api
      .get<{ data: AccountModel[] }>(
        `/${this.modelName}/read_by_owner_type_status?owner=${owner}&type=${type}&status=${status}`
      )
      .then(({ data }) => data.data);
  };

  readOwnerStatus = (owner?: string) => {
    return this.api
      .post<{ data: AccountOwnerStatusResponse }>(
        `/${this.modelName}/read_owner_status`,
        { owner }
      )
      .then(({ data }) => data.data);
  };

  generateDNUBAN = (payload: {
    owner: string;
    meta: string;
    tag: string;
    status: string;
    value: string;
  }) => {
    return this.createAccount({
      ...payload,
      type: AccountType.DNUBAN,
    });
  };

  generatePayoutAccount = (payload: {
    owner: string;
    meta: string;
    tag: string;
    status: string;
    value: string;
  }) => {
    return this.createAccount({
      ...payload,
      type: AccountType.NUBAN,
    });
  };

  createAccount = (payload: {
    owner: string;
    meta: string;
    tag: string;
    status: string;
    type: string;
    value: string;
  }) => {
    return this.api.post(`/${this.modelName}/create`, payload);
  };

  updateAccountStatus = (payload: {
    _id: string;
    old_status: AccountStatus;
    new_status: AccountStatus;
  }) => {
    return this.api.post(`/${this.modelName}/update_status`, payload);
  };

  verifyPayoutAccount = (payload: {
    bankCode: number | string;
    accountNumber: number | string;
  }) => {
    return this.api
      .post<{
        code: number;
        message: string;
        data?: {
          status: boolean;
          message: string;
          data?: {
            account_number: string;
            account_name: string;
            bank_id: string;
          };
        };
      }>(`/verify/bank_account`, {
        bank_code: payload.bankCode,
        account_number: payload.accountNumber,
      })
      .then((data) => data.data);
  };

  readAccountReconciliation = () => {
    return this.api
      .get<AccountReconciliationPayload>(`/${this.modelName}/reconciliation`)
      .then((data) => data.data);
  };

  readVendorAccountReconciliation = (vendorId: string) => {
    return this.api
      .post<AccountReconciliationVendorPayload>(
        `/${this.modelName}/vendor_reconciliation`,
        { owner: vendorId }
      )
      .then((data) => data.data);
  };

  auditFundingRequest = ({
    vendorId,
    loanRequestId,
  }: {
    vendorId: string;
    loanRequestId: string;
  }) => {
    const requestBody = {
      owner: vendorId,
      funding_request_id: loanRequestId,
    };
    return this.api
      .post<AccountAuditLoanRequestPayload>(
        `/${this.modelName}/audit_funding_request`,
        requestBody
      )
      .then((data) => data.data);
  };

  penaltyFundingRequest = ({
    vendorId,
    loanRequestId,
  }: {
    vendorId: string;
    loanRequestId: string;
  }) => {
    const requestBody = {
      owner: vendorId,
      funding_request_id: loanRequestId,
    };
    return this.api
      .post<AccountPenaltyLoanRequestPayload>(
        `/${this.modelName}/penalty_funding_request`,
        requestBody
      )
      .then((data) => data.data);
  };
}

export const accountService = new AccountService();
