import { get as getObjectByPath } from "lodash";

export function sortFunction(accessor: string) {
  return (rowA: any, rowB: any, columnId: string) =>
    getObjectByPath(rowA.original, accessor) <
    getObjectByPath(rowB.original, accessor)
      ? 1
      : -1;
}

export function encodeSortParams(params?: {
  id: string | number | symbol;
  desc: boolean;
}): string {
  if (!params) return "";
  const { id, desc } = params;
  return `${id.toString()}|${desc}`;
}

export function decodeSortParams(params: string) {
  let [id, desc] = params.split("|");
  if (!id || !desc) return {};
  try {
    return {
      id,
      desc: JSON.parse(desc || ""),
    };
  } catch (error) {
    return {};
  }
}
