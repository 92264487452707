export enum BusinessDetailTag {
  Address = "address",
  BVN = "bvn",

  IdentityCard = "identity_card",
  Passport = "passport",
  UtilityBill = "utility_bill",

  CACCertificate = "cac_certificate",
  CACStatus_Report = "cac_status_report",

  CACForm = "cac_form",
  CACMemorandum = "cac_memorandum",
  RegType = "registration_type",
  Others = "other_documents",
}

export enum BusinessDetailStatus {
  Verified = "verified",
  Unverified = "unverified",
}

export enum CACRegistrationType {
  Business = "business",
  Company = "company",
}

export const UniqueBusinessTags = [
  BusinessDetailTag.BVN,
  BusinessDetailTag.IdentityCard,
  BusinessDetailTag.Passport,
  BusinessDetailTag.RegType,
];
