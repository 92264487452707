export enum CauserType {
  Admin = "Admin",
  User = "User",
}

export enum SubjectType {
  Admin = "Admin",
  User = "User",
  Business = "Business",
  ClientReference = "ClientReference",
  LoanRequest = "LoanRequest",
  RateSetting = "RateSetting",
  Setting = "Setting",
  Upload = "Upload",
}

export enum ActionType {
  Create = "create",
  Update = "update",
  Delete = "delete",
  Login = "login",
}

export enum LogLevel {
  Info = "info",
  Debug = "debug",
  Warn = "warn",
  Error = "error",
  Critical = "critical",
}
