import { Box, Flex, Text, VStack } from "@chakra-ui/react";

enum PasswordValidation {
  Length = "length",
  LowercaseCharacter = "lowercaseCharacter",
  UppercaseCharacter = "uppercaseCharacter",
  SpecialCharacter = "specialCharacter",
  Number = "number",
}

interface PasswordValidations {
  [PasswordValidation.Length]?: boolean;
  [PasswordValidation.LowercaseCharacter]?: boolean;
  [PasswordValidation.UppercaseCharacter]?: boolean;
  [PasswordValidation.SpecialCharacter]?: boolean;
  [PasswordValidation.Number]?: boolean;
}

const passwordValidationRegex = {
  lowercaseCharacter: /[a-z]/,
  uppercaseCharacter: /[A-Z]/,
  specialCharacter: /\W/,
  number: /[0-9]/,
};

export const PasswordValidator = ({
  password,
  validations,
}: {
  password: string;
  validations: PasswordValidations;
}) => {
  return (
    <VStack alignItems="flex-start">
      <Text fontSize="sm" color="gray.800">
        Your password needs to be secure
      </Text>
      {Object.entries(validations).map(([rule]) => (
        <Validation
          key={rule}
          rule={
            rule === PasswordValidation.Length
              ? `At least 8 characters`
              : rule === PasswordValidation.LowercaseCharacter
              ? "One lowercase character"
              : rule === PasswordValidation.UppercaseCharacter
              ? "One uppercase character"
              : rule === PasswordValidation.SpecialCharacter
              ? "One special character"
              : rule === PasswordValidation.Number
              ? "One number"
              : ""
          }
          isValid={
            rule === PasswordValidation.Length
              ? password.trim().length >= 8
              : rule === PasswordValidation.LowercaseCharacter
              ? passwordValidationRegex.lowercaseCharacter.test(password)
              : rule === PasswordValidation.UppercaseCharacter
              ? passwordValidationRegex.uppercaseCharacter.test(password)
              : rule === PasswordValidation.SpecialCharacter
              ? passwordValidationRegex.specialCharacter.test(password)
              : rule === PasswordValidation.Number
              ? passwordValidationRegex.number.test(password)
              : false
          }
        />
      ))}
    </VStack>
  );
};

const Validation = ({ rule, isValid }: { rule: string; isValid: boolean }) => {
  return rule ? (
    <Flex gap="10px" alignItems="center">
      <Box
        h="8px"
        w="8px"
        borderRadius={999}
        bg={isValid ? "success.500" : "gray.300"}
      />
      <Text fontSize="sm" color="gray.700">
        {rule}
      </Text>
    </Flex>
  ) : null;
};
