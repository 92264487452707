import React from "react";

import { Button, Flex, Text, Tooltip } from "@chakra-ui/react";
import { fromUnixTime, getUnixTime, set } from "date-fns";
import DatePicker from "react-datepicker";
import { FiCalendar } from "react-icons/fi";

type DateInputProps = {
  size?: "sm" | "md";
  /** Unix timestamp */
  value: number;
  onChange: (date: number) => void;
  showTimeSelect?: boolean;
  isDisabled?: boolean;
  allowFutureDate?: boolean;
};
export const DateInput: React.FC<DateInputProps> = (props) => {
  const { value, onChange, ...rest } = props;
  const date = value ? fromUnixTime(value) : null;

  return (
    <Flex w="100%">
      <DatePicker
        selected={date}
        // maxDate={new Date()}
        maxDate={props.allowFutureDate ? undefined : new Date()}
        onChange={(newDate: Date) => {
          onChange(getUnixTime(newDate));
        }}
        placeholderText="Date"
        dateFormat={props.showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
        customInput={
          <CustomDateInput
            date={date}
            size={props.size}
            isDisabled={props.isDisabled}
          />
        }
        {...rest}
      />
    </Flex>
  );
};

type MonthYearPickerProps = {
  size?: "sm" | "md";
  /** Unix timestamp */
  value: number;
  onChange: (date: number) => void;
  isDisabled?: boolean;
  isFullWidth?: boolean;
};
export const MonthYearPicker: React.FC<MonthYearPickerProps> = (props) => {
  const { value, onChange, isFullWidth, ...rest } = props;
  const date = value ? fromUnixTime(value) : null;

  return (
    <Flex w="auto">
      <DatePicker
        selected={fromUnixTime(value)}
        onChange={(newDate: Date) => {
          onChange(getUnixTime(newDate));
        }}
        placeholderText="Month Year"
        showMonthYearPicker
        dateFormat={"MMMM yyyy"}
        customInput={
          <CustomDateInput
            date={date}
            size={props.size}
            isDisabled={props.isDisabled}
            isFullWidth={isFullWidth}
            isRounded={false}
            left
          />
        }
        {...rest}
      />
    </Flex>
  );
};

type DateRangeInputProps = {
  size?: "sm" | "md";
  /** Unix timestamp */
  start: number;
  /** Unix timestamp */
  end: number;
  onChange: (start: null | number, end: null | number) => void;
  showTimeSelect?: boolean;
  isDisabled?: boolean;
  allowFutureDate?: boolean;
};
export const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
  const { start, end, onChange, ...rest } = props;

  const startDate = start ? fromUnixTime(start) : null;
  const endDate = end ? fromUnixTime(end) : null;

  const formatStartDate = (date: Date) => {
    return getUnixTime(set(date, { hours: 0, minutes: 0, seconds: 0 }));
  };

  const formatEndDate = (date: Date) => {
    return getUnixTime(set(date, { hours: 23, minutes: 59, seconds: 0 }));
  };

  return (
    <Flex w="100%" position="relative" alignItems="center" gridGap={2}>
      <DatePicker
        selected={startDate}
        onChange={(newStartDate: Date) => {
          const newEndDate =
            endDate && newStartDate < endDate ? formatEndDate(endDate) : null;
          onChange(getUnixTime(newStartDate), newEndDate);
        }}
        startDate={startDate}
        endDate={endDate}
        maxDate={props.allowFutureDate ? undefined : new Date()}
        placeholderText="Start date"
        dateFormat={props.showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
        customInput={
          <CustomDateInput
            size={props.size}
            date={startDate}
            isDisabled={props.isDisabled}
            left
          />
        }
        {...rest}
      />
      <Text color="gray.600">to</Text>
      <DatePicker
        selected={endDate}
        onChange={(newEndDate: Date) => {
          onChange(
            startDate ? formatStartDate(startDate) : null,
            formatEndDate(newEndDate)
          );
        }}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={props.allowFutureDate ? undefined : new Date()}
        isDisabled={!startDate}
        placeholderText="End date"
        dateFormat={props.showTimeSelect ? "dd/MM/yyyy h:mm aa" : "dd/MM/yyyy"}
        customInput={
          <CustomDateInput
            size={props.size}
            date={endDate}
            isDisabled={props.isDisabled || !startDate}
            right
          />
        }
        {...rest}
      />
    </Flex>
  );
};

type CustomDateInputProps = {
  size?: "sm" | "md";
  date?: null | Date;
  left?: boolean;
  right?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isRounded?: boolean;
};
const CustomDateInput = React.forwardRef<any, CustomDateInputProps>(
  ({ value, onClick, ...props }: any, ref: any) => {
    return (
      <Button
        ref={ref}
        px={props.isRounded ? "6px" : undefined}
        size={props.size}
        variant="outline"
        borderRadius={props.isRounded ? "md" : undefined}
        isDisabled={props.isDisabled}
        leftIcon={<FiCalendar />}
        _hover={{ borderColor: "gray.300" }}
        onClick={!props.isDisabled ? onClick : null}
        width={props.isFullWidth ? "full" : undefined}
      >
        <Tooltip label={value || props?.placeholder} aria-label="Date tooltip">
          <Text
            color={value ? "black" : "gray.500"}
            lineHeight="20px"
            overflow="scroll"
            noOfLines={1}
          >
            {value || props?.placeholder}
          </Text>
        </Tooltip>
      </Button>
    );
  }
);
CustomDateInput.displayName = "CustomDateInput";
CustomDateInput.defaultProps = {
  size: "sm",
  isFullWidth: true,
  isRounded: true,
};
