import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  StackDirection,
  StackProps,
} from "@chakra-ui/react";
import React from "react";

export type FormGroupProps = StackProps & {
  label?: string;
  helperText?: string;

  /** Column sizes default is [3, 6] */
  columns?: [number, number];

  isRequired?: boolean;

  /** Stack orientation */
  orientation?: "horizontal" | "vertical";
};

export const FormGroup: React.FC<FormGroupProps> = ({
  label,
  isRequired,
  helperText,
  columns,
  orientation,
  children,
  ...stackProps
}) => {
  let direction: StackDirection = ["column"];

  if (orientation === "horizontal") {
    direction = ["column", "column", "row"];
  }

  const leftFlex = orientation === "horizontal" ? (columns?.[0] ?? 3) / 12 : 0;
  const rightFlex = orientation === "horizontal" ? (columns?.[1] ?? 9) / 12 : 0;

  return (
    <Stack mb={3} direction={direction} {...stackProps}>
      {!!label || !!helperText ? (
        <Box flex={leftFlex}>
          <FormControl mb={0} isRequired={isRequired}>
            {!!label && (
              <FormLabel size="md" mb={0}>
                {label}
              </FormLabel>
            )}
            {!!helperText && (
              <FormHelperText color="gray.600" mt={1}>
                {helperText}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      ) : null}
      <Box flex={rightFlex}>{children}</Box>
    </Stack>
  );
};

FormGroup.defaultProps = {
  orientation: "vertical",
};
