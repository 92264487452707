export function getMatchedWords(baseText: string, compareText: string) {
  function getWords(text: string) {
    return (
      text.match(/\w+/g)?.map((value) => value.toString().toLowerCase()) || []
    );
  }

  const wordListMain = getWords(baseText);
  const wordListCompare = getWords(compareText);

  function compareWords(listMain: Array<string>, listCompare: Array<string>) {
    const output: {
      similarWords: Array<string>;
      differentWords: Array<string>;
    } = {
      similarWords: [],
      differentWords: [],
    };

    listMain.forEach((word) => {
      if (listCompare.includes(word)) {
        output.similarWords.push(word);
      } else {
        output.differentWords.push(word);
      }
    });
    return output;
  }

  const comparison = compareWords(wordListMain, wordListCompare);
  const ratio = Math.ceil(
    (100 * comparison.similarWords.length) / wordListMain.length
  );

  return { ratio, ...comparison };
}

export const capitalizeWord = (word: string): string => {
  return (
    String(word).charAt(0).toUpperCase() + String(word).slice(1).toLowerCase()
  );
};

// this function helps to secure account number
export function obscureString(data: string, visibleCount = 4) {
  return `${new Array(data.length - visibleCount).join("x")}${data.slice(
    -visibleCount
  )}`;
}
