import {
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useRef } from "react";

type RenderTriggerProps = {
  onOpen: VoidFunction;
};

interface ActionPromptProps {
  title?: string;
  message: string | JSX.Element;
  renderTrigger: (props: RenderTriggerProps) => JSX.Element;
  callback: (confirmed: boolean) => void;
  primaryButtonLabel?: string;
  primaryButtonProps?: ButtonProps;
  secondaryButtonLabel?: string;
  secondaryButtonProps?: ButtonProps;
}

export const ActionPrompt: FC<ActionPromptProps> = ({
  title,
  message,
  callback,
  renderTrigger,
  primaryButtonLabel = "Accept",
  primaryButtonProps,
  secondaryButtonLabel = "Decline",
  secondaryButtonProps,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const confirmationAction = (confirmed: boolean) => {
    callback(confirmed);
    onClose();
  };
  const secondaryButtonRef = useRef(null);
  return (
    <>
      {renderTrigger({ onOpen })}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={secondaryButtonRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {!!title && (
            <ModalHeader pb="0px" fontWeight="semibold">
              {title}
            </ModalHeader>
          )}
          <ModalBody>
            {typeof message === "string" ? <Text>{message}</Text> : message}
          </ModalBody>
          <ModalBody>
            <Flex align="center" justify="end" gap="12px">
              <Button
                ref={secondaryButtonRef}
                onClick={() => confirmationAction(false)}
                {...secondaryButtonProps}
              >
                {secondaryButtonLabel}
              </Button>
              <Button
                colorScheme="success"
                onClick={() => confirmationAction(true)}
                {...primaryButtonProps}
              >
                {primaryButtonLabel}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
