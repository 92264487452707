import {
  Box,
  BoxProps,
  Table as ChakraTable,
  TableHeadProps,
  TableProps as ChakraTableProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import {
  Column,
  Row,
  TableHeaderProps,
  TableRowProps,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Pagination } from "./Pagination";

export interface TableQueryProps<T = any> {
  page?: number;
  sort?: { id: keyof T; desc: boolean };
  onQueryChange?: (
    query: Pick<Partial<TableQueryProps<T>>, "page" | "sort">
  ) => void;
}

interface TableProps extends ChakraTableProps {
  columns: any[];
  data: any[];
  columnHeaderProps?: (column: Column) => Partial<TableHeaderProps>;
  rowProps?: (row: Row<any>) => Partial<TableRowProps>;
  queryProps?: TableQueryProps;
  tableContainerProps?: BoxProps;
  tableHeaderProps?: TableHeadProps;
}

export const Table = ({
  columns,
  data,
  queryProps,
  columnHeaderProps,
  rowProps,
  tableContainerProps,
  tableHeaderProps,
  ...tableProps
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: rows,
    prepareRow,
    gotoPage,
    setSortBy,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    queryProps?.page && gotoPage(queryProps?.page - 1);
  }, [queryProps?.page, gotoPage]);

  React.useEffect(() => {
    queryProps?.sort &&
      setSortBy([
        { id: queryProps?.sort.id as string, desc: queryProps?.sort.desc },
      ]);
  }, [queryProps?.sort, setSortBy]);

  return (
    <Box {...tableContainerProps}>
      <Box overflow="auto">
        <ChakraTable
          {...getTableProps()}
          {...tableProps}
          css={{ tableLayout: "auto", width: "auto" }}
        >
          <Thead {...tableHeaderProps}>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line
                  <Th
                    {...column.getHeaderProps(columnHeaderProps?.(column))}
                    cursor="default"
                    onClick={() => {
                      queryProps?.onQueryChange?.({
                        sort: {
                          id: column.id,
                          desc: !column.isSortedDesc,
                        },
                      });
                    }}
                  >
                    {column.render("Header")}

                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line
                <Tr {...row.getRowProps(rowProps?.(row))}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line
                      <Td
                        {...cell.getCellProps()}
                        css={{
                          width: "1%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </ChakraTable>
      </Box>

      {!!queryProps?.page && pageCount > 1 && (
        <Box my={5}>
          {
            <Pagination
              count={pageCount}
              page={state.pageIndex + 1}
              onChange={(page) => queryProps?.onQueryChange?.({ page })}
            />
          }
        </Box>
      )}
    </Box>
  );
};
