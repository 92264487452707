export enum SettlementStatus {
  Pending = "pending",
  Queued = "queued",
  Sent = "sent",
  Split = "split",
}

export enum SettlementProvider {
  Paga = "paga",
}
