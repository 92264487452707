import { Box, Button, Divider, Flex, InputProps, Text } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FormField } from "./FormField";
import { List } from "./List";

type Option = {
  label: string;
  value: string;
};
interface FormSelectListProps extends Omit<InputProps, "value" | "onChange"> {
  label?: string;
  helperText?: string;
  value: string | null;
  options: Array<Option>;
  isLoading?: boolean;
  onChange: (value: string | null, option: Option) => void;
  renderCreateComponent?: (
    value: string,
    onCreateSuccess: VoidFunction
  ) => JSX.Element;
}
export const FormSelectList: FC<FormSelectListProps> = (props) => {
  const {
    label = "Select Option",
    helperText,
    value,
    options,
    isLoading,
    onChange,
    renderCreateComponent,
  } = props;
  const [query, setQuery] = useState("");
  const [createMode, setCreateMode] = useState(false);
  const toggleCreateMode = () => setCreateMode((prev) => !prev);
  const filteredOptions = useMemo(() => {
    return query
      ? options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        )
      : options;
  }, [query, options]);

  return (
    <>
      {createMode && !!renderCreateComponent ? (
        renderCreateComponent(query, toggleCreateMode)
      ) : (
        <>
          <Box mb="10px">
            <Text fontSize="md" fontWeight="medium">
              {label}
            </Text>
            {!!helperText && (
              <Text fontSize="sm" color="gray.700">
                {helperText}
              </Text>
            )}
          </Box>
          <FormField
            placeholder="Search"
            value={query || ""}
            onChange={(event: any) =>
              setQuery(event?.currentTarget?.value || "")
            }
            disabled={isLoading}
          />
          {!filteredOptions.length && (
            <Box mt="20px" px="20px" textAlign="center">
              {!!query ? (
                <Text mb="6px">
                  <Text as="span" fontWeight="medium">
                    &quot;{query}&quot;
                  </Text>{" "}
                  did not match any record.{" "}
                </Text>
              ) : (
                <Text mb="6px" color="gray.700" fontStyle="italic">
                  No record found
                </Text>
              )}
              {!!renderCreateComponent && (
                <Button
                  size="sm"
                  variant="link"
                  colorScheme="primary"
                  onClick={() => setCreateMode(true)}
                >
                  Create new
                </Button>
              )}
            </Box>
          )}

          {!!filteredOptions.length && (
            <Box
              mt="15px"
              bgColor="white"
              border="1px solid"
              borderColor="gray.100"
              borderRadius="6px"
            >
              <List
                align="start"
                items={filteredOptions}
                renderItem={(item: Option) => renderItem(item, value, onChange)}
                divider={<Divider />}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

const renderItem = (
  item: Option,
  value: string | null,
  onChange: (value: string | null, option: Option) => void
) => {
  const isSelected = value === item.value;
  return (
    <Flex
      key={item.value}
      w="full"
      p="10px 15px"
      align="center"
      justify="space-between"
    >
      <Text
        flexGrow={1}
        {...(!isSelected
          ? {
              onClick: () => onChange(item?.value, item),
              cursor: "pointer",
              _hover: {
                color: "gray.100",
              },
            }
          : {})}
      >
        {item.label}
      </Text>
      {isSelected && (
        <Text color="success.600">
          <BsFillCheckCircleFill />
        </Text>
      )}
    </Flex>
  );
};
