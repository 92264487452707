import { UserType } from "hooks";
import { api } from "utils";

export class AuthService<Model = any> {
  protected modelName = "";
  protected api = api;

  constructor(modelName: string) {
    this.modelName = modelName;
  }

  login = (payload: { email: string; password: string; otp?: string }) => {
    const { otp, ...rest } = payload;
    return this.api.post<{ accessToken: string; expiresIn: string }>(
      `/${this.modelName}/login${otp ? `?otp=${otp}` : ""}`,
      rest
    );
  };

  changePassword = (payload: {
    old_password: string;
    password: string;
    password_confirmation: string;
  }) => {
    return this.api.post(`/${this.modelName}/change_password`, payload);
  };

  requestPasswordReset = (payload: { email: string; redirect_url: string }) => {
    return this.api
      .post<{ message: string }>(
        `/${this.modelName}/request_password_reset`,
        payload
      )
      .then((data) => data.data);
  };

  resetPassword = (payload: {
    token: string;
    password: string;
    password_confirmation?: string;
  }) => {
    return this.api.post(`/${this.modelName}/reset_password`, payload);
  };

  refresh = () => {
    return this.api.post(`/${this.modelName}/refresh`);
  };

  user = () => {
    return this.api
      .get<{ data: Model }>(`/${this.modelName}/user`)
      .then(({ data }) => data.data);
  };
}

export class AuthenticatorService {
  protected modelName = "authenticator";
  protected api = api;

  enable2FA = () => {
    return this.api
      .post<{ data: string; message: string }>(`/${this.modelName}/enable`)
      .then(({ data }) => data);
  };

  disable2FA = (password: string) => {
    return this.api
      .post<{ message: string }>(`/${this.modelName}/disable`, { password })
      .then(({ data }) => data)
      .catch(({ response }) => Promise.reject(response.data));
  };

  statusCheck = (payload: { email: string; account: UserType }) => {
    return this.api
      .get<{ enabled: boolean }>(
        `/${this.modelName}/status_check?email=${payload.email}&account=${payload.account}`
      )
      .then(({ data }) => data)
      .catch(({ response: { data } }) => Promise.reject(data));
  };
}

export const authenticatorService = new AuthenticatorService();
