import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
  baseStyle: {
    bg: "#ffffff",
    fontWeight: "medium",
    borderRadius: "6px",
  },
  sizes: {
    sm: {
      fontSize: "md",
      height: "34px",
    },
  },
  variants: {
    link: {
      padding: 0,
      backgroundColor: "transparent",
      _hover: {
        textDecoration: "none",
      },
    },
    subtle: (props) => {
      return {
        backgroundColor: `${props.colorScheme}.50`,
        color: `${props.colorScheme}.700`,
      };
    },
  },
};
