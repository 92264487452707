export const routes = {
  user: {
    login: "/login",
    signup: "/signup",
    onboarding: "/onboarding",
    reset: "/reset",
    dashboard: "/",
    loanRequests: "/loan-requests",
    transactions: "/transaction",
    settings: "/settings",
  },
  admin: {
    login: "/backstage/login",
    dashboard: "/backstage",
    loanRequests: "/backstage/loan-requests",
    vendors: "/backstage/vendors",
    clients: "/backstage/clients",
    clientReferences: "/backstage/client-references",
    creditProviders: "/backstage/credit-providers",
    users: "/backstage/users",
    administrators: "/backstage/administrators",
    rateSettings: "/backstage/rate-settings",
    accountStatement: "/backstage/account-statement",
    settlement: "/backstage/settlement",
    inflow: "/backstage/inflow",
    reports: "#",
    settings: "/backstage/settings",
    manageAccount: "/backstage/manage-account",
    activitiesLog: "/backstage/activities-log",
  },
};
