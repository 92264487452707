import { NewLoanRequestModel } from "components/loan-request";
import { addMinutes, fromUnixTime, isAfter } from "date-fns";
import { useEffect, useState } from "react";

export function useDraftLoanRequest(draftKey: string | null) {
  const [initializing, setInitializing] = useState(true);
  const [draft, setDraft] = useState<string>();
  const [isPromptOpen, setPrompt] = useState(false);

  const togglePrompt = (fromStart?: boolean) => {
    if (fromStart) {
      draftKey && localStorage.removeItem(draftKey);
      setDraft(undefined);
    }
    setPrompt(false);
  };

  const updateDraft = (value?: string) => {
    if (!draftKey) return;
    if (value) {
      localStorage.setItem(draftKey, value);
      return;
    }
    localStorage.removeItem(draftKey);
  };

  useEffect(() => {
    setInitializing(true);
    if (draftKey) {
      try {
        let draftDataString = localStorage.getItem(draftKey)?.toString();
        if (draftDataString) {
          const requestData: NewLoanRequestModel = JSON.parse(draftDataString);
          if (requestData.lastOtpRequestAt) {
            // has otp lasted longer than 15min since generated?
            const expired = isAfter(
              new Date(),
              addMinutes(fromUnixTime(requestData.lastOtpRequestAt), 15)
            );
            if (expired) {
              requestData.lastOtpRequestAt = null;
              draftDataString = JSON.stringify(requestData);
              setPrompt(true);
            } else {
              // don't show draft prompt. OTP dialog should be displayed instead
              setPrompt(false);
            }
          } else {
            setPrompt(true);
          }
          setDraft(draftDataString);
        }
        setInitializing(false);
      } catch (error) {
        setInitializing(false);
      }
    }
  }, [draftKey]);

  return {
    isLoading: initializing,
    isPromptOpen,
    draft,
    updateDraft,
    togglePrompt,
  };
}
