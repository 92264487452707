import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiDatabase2Line } from "react-icons/ri";
import { appEnv, appVersion } from "utils";

export const AppVersion = () => {
  return (
    <Flex gap="6px" alignItems="center">
      <Icon as={RiDatabase2Line} color="gray.400" fontSize="lg" />

      <Text fontSize="sm" color="gray.600" textTransform="capitalize">
        <Text as="span" fontWeight="medium">
          Version {appVersion}{" "}
        </Text>
        {appEnv !== "production" ? `- ${appEnv}` : null}
      </Text>
    </Flex>
  );
};
