import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import * as Yup from "yup";
/**
 * This function is type inference ready and will auto-validate the useForm with the proper values.
 *
 * @param schema - A valid yup schema
 * @param useFormProps
 * @returns
 */
export function useFormWithSchema<T extends Yup.AnyObjectSchema>(
  schema: T,
  useFormProps?: UseFormProps<Yup.Asserts<T>>
): UseFormReturn<Yup.Asserts<T>> {
  return useForm({
    ...useFormProps,
    mode: "all",
    resolver: yupResolver(schema),
  });
}
