import { Button, useDisclosure, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth, UserType } from "hooks/useAuth";
import { useToast } from "hooks/useToast";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  adminAuthService,
  authenticatorService,
  userAuthService,
} from "services";
import { setAccessToken } from "utils";
import * as Yup from "yup";
import { FormField, FormGroup } from "../common";
import { AuthCodeForm } from "./AuthCodeForm";

const schema = Yup.object({
  email: Yup.string().email().required().label("Email"),
  password: Yup.string().required("Password is required").label("Password"),
});

export type LoginFormInputType = Yup.InferType<typeof schema>;

interface LoginFormProps {
  userType: UserType;
  onSuccess?: () => void;
  email?: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  userType,
  onSuccess,
  email,
}) => {
  const { setAuth } = useAuth();
  const { toast } = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<LoginFormInputType>({
    resolver: yupResolver(schema),
    defaultValues: { email: email ?? "", password: "" },
    mode: "all",
  });

  // Set form email value when prop changes
  useEffect(() => {
    setValue("email", email ?? "");
  }, [email, setValue]);

  const mutationFunction =
    userType === UserType.Admin
      ? adminAuthService.login
      : userAuthService.login;
  const { mutate, isLoading } = useMutation(mutationFunction, {});

  const { isLoading: checkingOtpStatus, ...mutateOtpStatus } = useMutation(
    authenticatorService.statusCheck
  );

  const onSubmit = (data: LoginFormInputType, code?: string) => {
    mutate(
      { ...data, otp: code },
      {
        onSuccess: ({ data }) => {
          onSuccess?.();
          setAccessToken(data.accessToken, userType);
          setAuth((state) => ({
            ...state,
            userType,
          }));
          toast({
            description: "Login successful!",
            status: "success",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.message || "An unknown error occurred.",
            status: "error",
          });
        },
      }
    );
  };

  const onOtpStatusCheck = (data: LoginFormInputType) => {
    if (userType === UserType.Admin) {
      mutateOtpStatus.mutate(
        { email: data.email, account: UserType.Admin },
        {
          onSuccess: ({ enabled }) => {
            if (enabled) {
              onOpen();
            } else {
              onSubmit(data);
            }
          },
          onError: (error: any) => {
            toast({
              description: error?.message || "An unknown error occurred.",
              status: "error",
            });
          },
        }
      );
      return;
    }
    onSubmit(data);
  };

  const onSubmitCode = (code: string) => {
    onSubmit(getValues(), code);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onOtpStatusCheck)}>
        <VStack spacing="20px" alignItems="stretch">
          {!email && (
            <FormGroup mb="0">
              <FormField
                type="email"
                autoComplete="email"
                placeholder="Enter your email"
                errorMessage={errors.email?.message}
                {...register("email")}
              />
            </FormGroup>
          )}
          <FormGroup mb="0">
            <FormField
              type="password"
              placeholder="Enter your password"
              autoComplete="current-password"
              errorMessage={errors.password?.message}
              {...register("password")}
            />
          </FormGroup>
          <Button
            isLoading={isLoading || checkingOtpStatus}
            disabled={isLoading || !isValid}
            type="submit"
            colorScheme="primary"
            data-test="submit-login"
          >
            Continue
          </Button>
        </VStack>
      </form>
      <AuthCodeForm
        isOpen={isOpen}
        isLoading={isLoading || checkingOtpStatus}
        onClose={onClose}
        onSubmit={onSubmitCode}
      />
    </>
  );
};
