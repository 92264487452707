import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "components/auth";
import { theme } from "config";
import { useAppInstallation } from "hooks/useAppInstallation";
import type { AppProps } from "next/app";
import Head from "next/head";
import "react-datepicker/dist/react-datepicker.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "../styles/globals.css";
import "../styles/plugins/react-datepicker.css";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  useAppInstallation();
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider pageProps={pageProps}>
          <Head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <Component {...pageProps} />
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default MyApp;
