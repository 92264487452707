import React from 'react'
import { Text, Box, Flex, SimpleGrid } from '@chakra-ui/layout'
import { Radio, RadioGroup as DefaultRadioGroup } from '@chakra-ui/react'
import { FiCheckCircle } from 'react-icons/fi'

type RadioGroupProps = {
  value: any
  options: { label: string; value: string; description?: string }[]
  onChange: (value: any) => void
  isDisabled?: boolean
}

export const RadioCardGroup: React.FC<RadioGroupProps> = (props) => {
  const { options, ...rest } = props
  return (
    <>
      <DefaultRadioGroup colorScheme="primary" {...rest}>
        <SimpleGrid columns={2} gridGap={3}>
          {options.map(({ label, value, description }) => (
            <Flex
              key={value}
              p="12px"
              cursor="pointer"
              borderRadius="md"
              border="1px solid"
              alignItems="center"
              justifyContent="space-between"
              bg={value === props.value ? 'primary.50' : 'white'}
              borderColor={value === props.value ? 'primary.100' : 'gray.200'}
              transition="all 300ms"
              onClick={() => !props.isDisabled && props.onChange(value)}
            >
              <Flex flexDir="column" justifyContent="center">
                <Radio display="none" value={value}>
                  {label}
                </Radio>
                <Text fontWeight="medium">{label}</Text>
                {!!description && (
                  <Text fontSize="sm" color="gray.600">
                    {description}
                  </Text>
                )}
              </Flex>

              <Box color="primary.600">
                {value === props.value && <FiCheckCircle />}
              </Box>
            </Flex>
          ))}
        </SimpleGrid>
      </DefaultRadioGroup>
    </>
  )
}
