import { Flex, Spinner } from "@chakra-ui/react";
import { routes } from "config/routes";
import { useAuth, UserType } from "hooks/useAuth";
import { useRouter } from "next/router";
import { PropsWithChildren, useEffect, useState } from "react";

export const AuthProvider = ({
  pageProps,
  children,
}: PropsWithChildren<{
  pageProps: { auth?: UserType; guest?: UserType };
}>) => {
  const router = useRouter();
  const {
    auth: { userType, isLoading },
    isAuthenticated,
  } = useAuth();
  const [ready, setDone] = useState(false);

  useEffect(() => {
    // For authenticated pages
    if (pageProps.auth && !isLoading) {
      // Redirect to designated login page if user is not authenticated
      if (!isAuthenticated) {
        router.push(routes[pageProps.auth].login).then(() => {
          setDone(!isLoading);
        });
      }
      // If user is authenticated but not allowed here, redirect to their dashboard
      // Alternative, show and access denied page?
      else if (userType && pageProps.auth !== userType) {
        router.push(routes[userType!].dashboard).then(() => {
          setDone(!isLoading);
        });
      }
      // If all is well, keep them here
      else {
        setDone(!isLoading);
      }
    }
    // For guest pages, redirect user to their designated dashboard
    else if (pageProps.guest && !isLoading && isAuthenticated && userType) {
      router.push(routes[userType!].dashboard).then(() => {
        setDone(!isLoading);
      });
    } else {
      setDone(!isLoading);
    }
  }, [isAuthenticated, isLoading, pageProps, router, userType]);

  return (
    <>
      {ready ? (
        children
      ) : (
        <Flex
          maxW="350px"
          height="100vh"
          margin="0 auto"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="primary.500" />
        </Flex>
      )}
    </>
  );
};
