import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import EmptyFolderIcon from "./images/empty-folder-gray.svg";
import NoCloudIcon from "./images/no-cloud.svg";
import SecurityIcon from "./images/unauthorized.svg";

export type ErrorBoxProps = {
  /**
   * Network error, react suspend error or authorization error
   * from permission check
   */
  type?: "network" | "suspense" | "authorization";
  icon?: string;
  title?: string;
  /** Error message */
  message?: string;
  /** Error reference. Usually the permission string */
  reference?: string;
  /**
   * Vertical margin shortcut, so you don't need
   * to wrap component in a <Box /> or <Flex />
   */
  my?: number;
};

export const ErrorBox: React.FC<ErrorBoxProps> = ({ type, my, ...props }) => {
  let content = type ? defaults[type] : defaults.default;

  content = {
    ...content,
    ...props, // override default content with props
  };

  return (
    <Container>
      <Flex justifyContent="center" my={my || 0}>
        <Box maxW="500px" textAlign="center">
          {!!content.icon && (
            <Image
              src={content.icon}
              alt={content?.title || content?.message}
            />
          )}

          {!!content.title && (
            <Heading
              as="h6"
              fontSize="xl"
              fontWeight="medium"
              mt={3}
              mb={1}
              data-testid="title"
            >
              {content.title}
            </Heading>
          )}

          <Text
            as="p"
            fontSize="sm"
            color="gray.600"
            mt={!content.title ? 3 : 0}
            data-testid="message"
          >
            {content.message}
          </Text>

          {!!props.reference && (
            <Text as="code" color="gray.500" fontSize="sm">
              Ref: {props.reference}
            </Text>
          )}
        </Box>
      </Flex>
    </Container>
  );
};

type Content = { icon?: string; title?: string; message?: string };
const defaults: Record<string, Content> = {
  network: {
    icon: NoCloudIcon,
    title: `You're offline`,
    message: `Seems like there's something wrong with your internet connection. Please reconnect and try again.`,
  },
  suspense: {
    icon: NoCloudIcon,
    title: `Ah! Something has gone wrong.`,
    message: `If refreshing your browser doesn't fix the problem, kindly contact the tech team.`,
  },
  authorization: {
    icon: SecurityIcon,
    title: `Unauthorized Access!`,
    message: `You don't have the permission to access this page. That's all we know.`,
  },
  default: {
    icon: EmptyFolderIcon,
  },
};
