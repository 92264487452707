import { Flex, FlexProps } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import React from "react";
import { ErrorBox } from "./ErrorBox";

export type ContentLoaderProps = FlexProps & {
  isLoading?: boolean;
  contentUnavailable?: boolean;
  errorTitle?: null | string;
  errorMessage?: null | string;
};

export const ContentLoader: React.FC<ContentLoaderProps> = ({
  isLoading,
  contentUnavailable,
  errorTitle,
  errorMessage,
  ...props
}) => {
  const showError = errorTitle || errorMessage;

  return (
    <>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" h={150} {...props}>
          <Spinner size="sm" color="primary.500" />
        </Flex>
      )}

      {!isLoading && contentUnavailable && showError ? (
        <Flex alignItems="center" h={150} {...props}>
          <ErrorBox title={errorTitle || ""} message={errorMessage || ""} />
        </Flex>
      ) : null}
    </>
  );
};
