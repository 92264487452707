import { ComponentStyleConfig } from "@chakra-ui/theme";

export const Card: ComponentStyleConfig = {
  parts: ["card", "header", "body", "footer"],
  baseStyle: {
    card: {
      bg: "white",
      borderRadius: "8px",
      border: "1px solid",
      borderColor: "gray.50",
      boxShadow: "sm",
      transition: "all 300ms",
    },
    header: {
      display: "flex",
      alignItems: "center",
      borderRadius: "8px 8px 0 0",
      justifyContent: "space-between",
      flexWrap: "wrap",
      mb: "20px",
    },
    body: {
      padding: "20px",
    },
    footer: {
      borderTop: "1px solid",
      borderColor: "gray.100",
      padding: "20px",
    },
  },
};
