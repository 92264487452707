import { useRouter } from "next/router";
import React from "react";

interface RoutePromptProps {
  when: boolean;
  message: string;
}

export const RoutePrompt: React.FC<RoutePromptProps> = ({ when, message }) => {
  const router = useRouter();
  React.useEffect(() => {
    const handler = () => {
      if (when && !window.confirm(message)) {
        throw "User aborted navigation";
      }
    };
    router.events.on("beforeHistoryChange", handler);
    return () => router.events.off("beforeHistoryChange", handler);
  }, [when, message, router.events]);
  return null;
};
