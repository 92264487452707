import { useToast as useChakraToast, UseToastOptions } from "@chakra-ui/react";
import React from "react";

const defaultOptions: UseToastOptions = {
  position: "bottom",
};

interface IUseToast {
  toast: (options: UseToastOptions) => void;
  closeAllToasts: VoidFunction;
}

export function useToast(): IUseToast {
  const chakraToast = useChakraToast();

  const toast: IUseToast["toast"] = React.useCallback(
    (options) => {
      chakraToast({ ...defaultOptions, ...options });
    },
    [chakraToast]
  );

  const closeAllToasts = React.useCallback((): void => {
    chakraToast.closeAll();
  }, [chakraToast]);

  return { toast, closeAllToasts };
}
