import { SettingTag } from "@obtainly-v2/enums";
import { SettingModel } from "@obtainly-v2/schema";
import { ModelService } from "./Model";

class SettingService extends ModelService<SettingModel> {
  constructor() {
    super("settings");
  }

  readByTag = (tag?: SettingTag) => {
    return this.api
      .get<{ data: SettingModel }>(`/${this.modelName}/read_by_tag?tag=${tag}`)
      .then(({ data }) => data.data);
  };
}

export const settingService = new SettingService();
