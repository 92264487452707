import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { getUnixTime } from "date-fns";
import { useFormWithSchema, useToast } from "hooks";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { otpService } from "services";
import * as Yup from "yup";

interface OtpDialogProps {
  title?: string;
  isOpen: boolean;
  lastRequestedAt: number | null;
  onOtpRequest?: (timestamp: number) => void;
  onClose: VoidFunction;
  onSubmit: (otp: string) => Promise<void>;
}

const formSchema = Yup.object({
  hash: Yup.string()
    .matches(/[0-9]{6}/g, "Invalid OTP")
    .required()
    .label("One Time Password"),
});

type FormInputType = Yup.InferType<typeof formSchema>;

const OTP_TIMER = 60; // seconds

export const OtpDialog: React.FC<OtpDialogProps> = ({
  isOpen,
  lastRequestedAt,
  onOtpRequest,
  onSubmit,
  onClose,
}) => {
  const { toast } = useToast();
  const {
    formState: { isValid, isDirty },
    control,
    handleSubmit,
    reset,
  } = useFormWithSchema(formSchema, {
    defaultValues: { hash: "" },
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [counter, setCounter] = useState<number | null>(null);

  const { isLoading, mutate: generateOtp } = useMutation(
    "otp_generate",
    otpService.generate,
    {
      onSuccess: ({ message }) => {
        onOtpRequest?.(getUnixTime(new Date()));
        setCounter(OTP_TIMER);
        toast({
          description: message,
          status: "success",
        });
      },
      onError: (error: any) => {
        toast({
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
        });
      },
    }
  );

  const onSubmitOtpForm = ({ hash }: FormInputType) => {
    setSubmitting(true);
    onSubmit(hash)
      .then(() => onClose())
      .finally(() => {
        setSubmitting(false);
        reset();
      });
  };

  useEffect(() => {
    let countClock: NodeJS.Timer;
    countClock = setInterval(() => {
      counter !== null &&
        counter > 0 &&
        setCounter((prev) => (prev ? --prev : null));
    }, 1000);
    return () => clearTimeout(countClock);
  }, [counter]);

  useEffect(() => {
    // continue count down
    // from last time otp was generated
    if (lastRequestedAt) {
      const elapsedSeconds = getUnixTime(new Date()) - lastRequestedAt;
      if (elapsedSeconds < OTP_TIMER) {
        setCounter(OTP_TIMER - elapsedSeconds);
      }
    }

    // auto generate
    if (isOpen && !lastRequestedAt && !counter) {
      generateOtp();
    }
  }, [isOpen, counter, lastRequestedAt]); // eslint-disable-line

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody h="auto" maxH="auto">
            <Heading size="md" my="20px" textAlign="center">
              Verification Code
            </Heading>
            <Text color="gray.700" textAlign="center" mb="20px">
              Please enter verification code sent to your phone, email and
              WhatsApp
            </Text>
            <Text
              textAlign="center"
              mb="6px"
              fontSize="14px"
              fontWeight="medium"
            >
              One Time Password (OTP)
            </Text>
            <form onSubmit={handleSubmit(onSubmitOtpForm)}>
              <Controller
                name="hash"
                control={control}
                render={({
                  field: { ref, value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <HStack align="center" justify="center">
                    <PinInput
                      otp
                      type="number"
                      value={value}
                      onChange={(newValue) =>
                        onChange({ target: { value: newValue } })
                      }
                      isInvalid={!!error?.message}
                      isDisabled={isSubmitting}
                      {...rest}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                )}
              />
              <Box textAlign="center" my="20px">
                <Button
                  colorScheme="primary"
                  type="submit"
                  isDisabled={isLoading || !isValid || !isDirty}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </form>
            <Box textAlign="center" mt="20px" fontSize="14px">
              <Text color="gray.700">Didn&apos;t receive an OTP?</Text>
              <Text>
                <Button
                  variant="link"
                  colorScheme="primary"
                  onClick={() => generateOtp()}
                  isDisabled={
                    isLoading || !!(counter && counter > 0) || isSubmitting
                  }
                >
                  Resend OTP {counter ? `in ${counter}s` : null}
                </Button>
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
