import {
  Box,
  Button,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

interface FilePreviewerProps {
  data?: string;
  buttonLabel?: string;
  buttonProps?: ButtonProps;
}

export const FilePreviewer: React.FC<FilePreviewerProps> = ({
  data,
  buttonLabel,
  buttonProps,
}) => {
  const boxRef = React.useRef<any>();
  const [boxHeight, setBoxHeight] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setIframeHeight = () => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current?.clientHeight);
    }
  };

  if (!data) return null;
  if (
    !(
      data.startsWith("https://") ||
      data.startsWith("http://") ||
      data.startsWith("data:")
    )
  )
    return null;
  return (
    <>
      <Button {...buttonProps} onClick={onOpen}>
        {buttonLabel ?? "View File"}
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Document Preview</DrawerHeader>
          <DrawerBody ref={boxRef}>
            <Box boxShadow="md" rounded="md" bg="white" overflow="hidden">
              <iframe
                onLoad={setIframeHeight}
                width="100%"
                height={`${boxHeight - 40}px`}
                src={data}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default FilePreviewer;
