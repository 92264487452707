import { LoanRequestStatus } from "@obtainly-v2/enums";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { accountService } from "services";

export function useLoanRequestPayment(loanRequest: LoanRequestModel) {
  const isActive =
    loanRequest.status === LoanRequestStatus.Active && !!loanRequest.approvedAt;
  const isPaid =
    loanRequest.status === LoanRequestStatus.Paid && !!loanRequest.paidBackAt;

  const { data: auditData, isLoading } = useQuery(
    `account_audit_funding_request_${loanRequest._id}_${loanRequest.vendorId}`,
    () =>
      accountService.auditFundingRequest({
        loanRequestId: loanRequest._id,
        vendorId: loanRequest.vendorId,
      }),
    {
      enabled:
        !!loanRequest._id &&
        !!loanRequest.vendorId &&
        [
          LoanRequestStatus.Active,
          LoanRequestStatus.Paid,
          LoanRequestStatus.Unpaid,
        ].includes(loanRequest.status),
    }
  );

  const paymentValues = useMemo(() => {
    return {
      amountPayback: (auditData?.data?.amount_payback || 0) / 100,
      amountPaid: (auditData?.data?.amount_paid || 0) / 100,
      amountDue: (auditData?.data?.amount_due || 0) / 100,
      cashBack: (auditData?.data?.amount_cashback || 0) / 100,
    };
  }, [auditData]);

  return { ...paymentValues, isActive, isPaid, isLoading, auditData };
}
