import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
} from "@chakra-ui/react";
import NextImage from "next/image";
import { FC, useRef, useState } from "react";

interface FilePreviewerProps {
  data?: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const FilePreviewer: FC<FilePreviewerProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  const boxRef = useRef<any>();
  const [boxHeight, setBoxHeight] = useState(0);

  const setIframeHeight = () => {
    if (boxRef.current) {
      setBoxHeight(boxRef.current?.clientHeight);
    }
  };

  if (!data) return null;
  if (
    !(
      data.startsWith("https://") ||
      data.startsWith("http://") ||
      data.startsWith("data:")
    )
  )
    return null;
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Document Preview</DrawerHeader>
          <DrawerBody ref={boxRef}>
            <Box boxShadow="md" rounded="md" bg="white" overflow="hidden">
              {data.startsWith("data:image") ||
              data.endsWith(".jpg") ||
              data.endsWith(".jpeg") ||
              data.endsWith(".png") ? (
                <Box position="relative" boxSize="calc(100vh - 100px)">
                  <Image
                    alt="image-viewer"
                    as={NextImage}
                    layout="fill"
                    objectFit="contain"
                    src={data}
                  />
                </Box>
              ) : (
                <iframe
                  title="file-preview-window"
                  onLoad={setIframeHeight}
                  width="100%"
                  height={`${boxHeight - 40}px`}
                  src={data}
                />
              )}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
