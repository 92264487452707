import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { api } from "utils";

interface IArgs {
  uploadUrl: string;
  fileData: Record<string, any>;
  file: File;
}

export const useFileUploadMutation = <Model = any>(): UseMutationResult<
  { status: string; message: string; data: Model },
  AxiosError,
  IArgs
> & {
  progress?: number;
} => {
  const [progress, setProgress] = useState<number>();

  const mutation = useMutation<
    { status: string; message: string; data: Model },
    AxiosError,
    IArgs
  >(({ uploadUrl, file, fileData }) => {
    const data = new FormData();
    data.append("file", file);
    for (let key in fileData) {
      data.append(key, fileData[key]);
    }
    return api
      .post(uploadUrl, data, {
        onUploadProgress: (ev) =>
          setProgress(Math.round((ev.loaded * 100) / ev.total)),
      })
      .then(({ data }) => data)
      .catch((response) => response)
      .finally(() => setProgress(0));
  });

  return { ...mutation, progress };
};
