import { AccountStatementStatus, LedgerSubhead } from "@obtainly-v2/enums";

// this function help reword transaction type
export const getAccountStatementStatus = (status: string) => {
  switch (status) {
    case AccountStatementStatus.Funding:
      return "LPO Funding";
    case AccountStatementStatus.CashBackIn:
      return "Cashback IN";
    case AccountStatementStatus.CashBackOut:
      return "Cashback OUT";
    case AccountStatementStatus.PayBack:
      return "Pay BACK";
    case AccountStatementStatus.PayIn:
    case LedgerSubhead.Transfers:
      return "Pay IN";
    case AccountStatementStatus.PayOut:
      return "Pay OUT";
    default:
      return status;
  }
};

export const filterAccountStatement = ({ data, filterValue }: any) => {
  return data.filter((item: any) => item.tag.includes(filterValue));
};
