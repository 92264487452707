import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserModel } from "@obtainly-v2/schema";
import { FormField, FormGroup, PasswordValidator } from "components/common";
import { useToast } from "hooks";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { userAuthService } from "services";
import * as Yup from "yup";

const schema = Yup.object({
  old_password: Yup.string().required().label("Old Password"),
  password: Yup.string()
    .required()
    .min(8)
    .matches(/[a-z]/, "Password must contain one lowercase letter")
    .matches(/[A-Z]/, "Password must contain one uppercase letter")
    .matches(/[0-9]/, "Password must contain one number")
    .label("Password"),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .label("Password confirmation"),
});

interface PasswordChangeModalProps extends Partial<ModalProps> {
  user: UserModel;
  isDismissable?: boolean;
}

export const PasswordChangeModal = ({
  user,
  isDismissable = false,
  ...modalProps
}: PasswordChangeModalProps) => {
  const [isOpen, setOpen] = useState(true);
  const { toast } = useToast();

  const changePassword = useMutation(userAuthService.changePassword);

  const queryCache = useQueryClient();

  const {
    register,
    formState: { errors, touchedFields, isValid },
    watch,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    mode: "all",
  });

  const onSubmit = handleSubmit((values: Yup.InferType<typeof schema>) => {
    changePassword.mutate(values, {
      onSuccess: () => {
        queryCache.invalidateQueries();
        toast({
          description: "Password change successful!",
          status: "success",
        });
        if (modalProps.onClose) {
          modalProps.onClose();
        } else {
          setOpen(false);
        }
      },
      onError: (error: any) => {
        toast({
          description:
            error?.response?.data?.message || "An unknown error occurred.",
          status: "error",
        });
      },
    });
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      closeOnEsc={isDismissable}
      closeOnOverlayClick={isDismissable}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="0">
          <Heading fontSize="xl">Change Your Pasword</Heading>
          <Text fontSize="sm" color="gray.600" mt="6px">
            To make your account secure, please set a new password to replace
            the temporary password you were given on activation.
          </Text>
        </ModalHeader>
        {isDismissable && <ModalCloseButton />}
        <ModalBody>
          <form onSubmit={onSubmit}>
            <FormGroup label="Old password">
              <FormField
                type="password"
                hasError={!!errors.old_password?.message}
                {...register("old_password")}
              />
            </FormGroup>

            <FormGroup label="New password">
              <FormField
                type="password"
                hasError={!!errors.password?.message}
                {...register("password")}
              />
              {touchedFields.password && errors.password?.message ? (
                <Box mt="8px" ml="15px">
                  <PasswordValidator
                    password={watch("password")}
                    validations={{
                      length: true,
                      uppercaseCharacter: true,
                      lowercaseCharacter: true,
                      number: true,
                    }}
                  />
                </Box>
              ) : null}
            </FormGroup>

            <FormGroup label="Confirm Password">
              <FormField
                type="password"
                errorMessage={errors.password_confirmation?.message}
                {...register("password_confirmation")}
              />
            </FormGroup>

            <Button
              mt="10px"
              isLoading={changePassword.isLoading}
              isDisabled={changePassword.isLoading || !isValid}
              type="submit"
              colorScheme="primary"
              width="100%"
            >
              Set Password
            </Button>
            {isDismissable && (
              <Button
                mt="6px"
                type="button"
                variant="outline"
                width="100%"
                onClick={modalProps.onClose}
              >
                Cancel
              </Button>
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
