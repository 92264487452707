import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { SettingTag } from "@obtainly-v2/enums";
import { useAuth } from "hooks";
import { useAppInstallation } from "hooks/useAppInstallation";
import React from "react";
import { useQuery } from "react-query";
import { settingService } from "services";
import { appVersion } from "utils";

export const InstallationWizard: React.FC = () => {
  const {
    installation: { installEvent, isInstalled, isReady },
    installApp,
    updateApp,
  } = useAppInstallation();
  const { isAuthenticated } = useAuth();

  const { data: appVersionSetting, isLoading: isLoadingAppVersion } = useQuery(
    "setting.read_by_tag",
    () => settingService.readByTag(SettingTag.AppVersion),
    {
      enabled: isAuthenticated,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      refetchInterval: 15 * 60 * 1e3, // 15 mins refresh
    }
  );

  const installationRequired = !isInstalled && !!installEvent;
  const updateRequired =
    typeof appVersionSetting?.value === "string" &&
    appVersionSetting.value !== appVersion;

  return isReady && !isLoadingAppVersion ? (
    <>
      {updateRequired ? (
        <Box
          bgColor="primary.600"
          boxShadow="sm"
          width="100%"
          px="20px"
          py="10px"
        >
          <HStack justify="space-between" w="100%" gridGap={2}>
            <Text fontSize="sm" color="white">
              A new version of Obtainly is available. Update now for better
              experience.
            </Text>
            <Box>
              <Button onClick={updateApp} size="sm">
                Update
              </Button>
            </Box>
          </HStack>
        </Box>
      ) : installationRequired ? (
        <Box
          bgColor="primary.600"
          boxShadow="sm"
          width="100%"
          px="20px"
          py="10px"
        >
          <HStack justify="space-between" w="100%" gridGap={2}>
            <Text fontSize="sm" color="white">
              Install Obtainly app for free. It&apos;s fast and takes less
              storage on your device.
            </Text>
            <Box>
              <Button onClick={installApp} size="sm">
                Install
              </Button>
            </Box>
          </HStack>
        </Box>
      ) : null}
    </>
  ) : null;
};

export default InstallationWizard;
